import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CcInputsModule } from '@hs/ui-core-inputs';
import { CcLayoutModule } from '@hs/ui-core-layout';
import { CcPresentationModule } from '@hs/ui-core-presentation';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AuthComponent } from 'app/auth/auth.component';
import { AuthEffects } from 'app/auth/store/auth/auth.effects';
import { authKey, authReducer } from 'app/auth/store/auth/auth.reducer';
import { TwoFactorEffects } from 'app/auth/store/auth/two-factor.effects';
import { authMethodKey, authMethodReducer } from 'app/auth/store/auth-method/auth-method.reducer';
import { failureKey, failureReducer } from 'app/auth/store/failure/failure.reducer';
import { messageKey, messageReducer } from 'app/auth/store/message/message.reducer';
import { RedirectEffects } from 'app/auth/store/redirect/redirect.effects';
import { redirectKey, redirectReducer } from 'app/auth/store/redirect/redirect.reducer';
import { SessionEffects } from 'app/auth/store/session/session.effects';
import { sessionKey, sessionReducer } from 'app/auth/store/session/session.reducer';
import { SharedModule } from 'app/shared/shared.module';

import { AuthRoutingModule } from './auth-routing.module';
import { GuideLinesComponent } from './components/guidelines/guidelines.component';
import { MaintenanceWarningComponent } from './components/maintenance-warning/maintenance-warning.component';
import { NeedHelpComponent } from './components/need-help/need-help.component';
import { AuthenticationSelectionComponent } from './containers/authentication-selection/authentication-selection.component';
import { LoginPageComponent } from './containers/login-page/login-page.component';
import { LogoutComponent } from './containers/logout/logout.component';
import { PasscodeComponent } from './containers/passcode/passcode.component';
import { PasswordChangePageComponent } from './containers/password-change-page/password-change-page.component';
import { PushComponent } from './containers/push/push.component';
import { MaintenanceEffects } from './store/maintenance/maintenance.effects';
import { maintenanceKey, maintenanceReducer } from './store/maintenance/maintenance.reducer';

@NgModule({
    declarations: [
        LoginPageComponent,
        LogoutComponent,
        NeedHelpComponent,
        PasscodeComponent,
        PasswordChangePageComponent,
        AuthenticationSelectionComponent,
        PushComponent,
        AuthComponent,
        GuideLinesComponent,
        MaintenanceWarningComponent,
    ],
    imports: [
        AuthRoutingModule,
        BrowserModule,
        CcInputsModule,
        CcLayoutModule,
        CcPresentationModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        SharedModule,
        StoreModule.forFeature(authMethodKey, authMethodReducer),
        StoreModule.forFeature(failureKey, failureReducer),
        StoreModule.forFeature(messageKey, messageReducer),
        StoreModule.forFeature(redirectKey, redirectReducer),
        StoreModule.forFeature(sessionKey, sessionReducer),
        StoreModule.forFeature(authKey, authReducer),
        StoreModule.forFeature(maintenanceKey, maintenanceReducer),
        EffectsModule.forFeature([AuthEffects, RedirectEffects, SessionEffects, TwoFactorEffects, MaintenanceEffects]),
    ],
})
export class AuthModule {}
