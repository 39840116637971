import { Action, createReducer, on } from '@ngrx/store';

import { clearFailure, setFailure } from 'app/auth/store/failure/failure.actions';

import { FailureState } from './failure.state';

export const failureKey = 'failure';

const initialState: FailureState = false;

const reducer = createReducer(
    initialState,
    on(clearFailure, () => initialState),
    on(setFailure, () => true),
);

export function failureReducer(state: FailureState | undefined, action: Action): FailureState {
    return reducer(state, action);
}
