import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { defer, Observable, of } from 'rxjs';
import { catchError, delay, map, switchMap } from 'rxjs/operators';

import { checkSessionAfterTime, clearSession, sessionSet, setSession } from 'app/auth/store/session/session.actions';
import { AuthService } from 'app/core/services/auth/auth.service';
import { Logger } from 'app/shared/services/logging/logger';
import { NamedLogger } from 'app/shared/utils/logging/named-logger';

const sessionCheckInterval: number = 60 * 1000;

@Injectable()
export class SessionEffects extends NamedLogger {
    init$: Observable<Action> = createEffect(() => {
        return defer(() =>
            this.authService.getSessionInfo().pipe(
                map((session) => setSession({ session })),
                catchError((e) => {
                    if (e?.name !== 'HttpErrorResponse') {
                    // we don't need to log HTTP errors, we can see them in the network traffic
                        this.logError('Error fetching session info:', e);
                    }

                    return of(clearSession());
                }),
            ),
        );
    });

    setSession$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(setSession),
            switchMap((action) => {
                this.logInfo('Session:', action.session);
                return [
                    sessionSet({ session: action.session }),
                    checkSessionAfterTime({ milliseconds: action.session.timeLeftMs }),
                ];
            }));
    },
    );

    checkSessionAfterTime$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(checkSessionAfterTime),
            switchMap((action) => of(action).pipe(delay(action.milliseconds))),
            switchMap(() => this.authService.getSessionInfo()),
            map(() => checkSessionAfterTime({ milliseconds: sessionCheckInterval })),
            catchError(() => {
                this.router.navigate(['/login']).then(
                    (result) => this.logDebug('Navigation to /login succeeded?', result),
                    (error) => this.logError('Error navigating to /login:', error),
                );
                return of(clearSession());
            }));
    },
    );

    constructor(
        private readonly actions$: Actions,
        private readonly authService: AuthService,
        private readonly router: Router,
        logger: Logger,
    ) {
        super(logger);
    }
}
