import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, of } from 'rxjs';

import { MaintenanceService } from 'app/core/services/maintenance/maintenance.service';
import { Logger } from 'app/shared/services/logging/logger';
import { NamedLogger } from 'app/shared/utils/logging/named-logger';

import * as fromActions from './maintenance.actions';

@Injectable()
export class MaintenanceEffects extends NamedLogger {
    fetchMaintenanceWindowEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromActions.fetchMaintenanceWindowRequest),
            switchMap(() => this.maintenanceService.fetchMaintenanceWindow().pipe(
                map((maintenanceWindow) => fromActions.fetchMaintenanceWindowSuccess({
                    maintenanceWindow: {
                        start: maintenanceWindow.start ? new Date(maintenanceWindow.start) : null,
                        end: maintenanceWindow.end ? new Date(maintenanceWindow.end) : null,
                        isActive: maintenanceWindow.isActive,
                    },
                })),
                catchError((error: HttpErrorResponse) => of(fromActions.fetchMaintenanceWindowFailure(error))),
            )),
        );
    });

    constructor(
        private readonly actions$: Actions,
        private readonly maintenanceService: MaintenanceService,
        logger: Logger,
    ) {
        super(logger);
    }
}
