<div class="login-page">
    <div class="header">
        <p *ngIf="token" class="welcome-message">
            Welcome:
            <span class="username">{{ username }}</span>
            <cc-button
                [type]="'naked'"
                [tabIndex]="1"
                (click)="reset()"
            >Change
            </cc-button>
        </p>
    </div>

    <form [formGroup]="loginForm" autocomplete="off" (ngSubmit)="submit()">
        <app-input [hidden]="token" #userInput type="text" formControlName="username" name="username" label="Email address" [tabIndex]="2"></app-input>
        <app-input [hidden]="!token" #passInput type="password" formControlName="password" name="password" label="Password" [tabIndex]="2"></app-input>
        <div class="actions">
            <div class="action-links">
                <cc-checkbox
                    *ngIf="trustedDeviceEnabled && token && !deviceIsTrusted"
                    id="remember-device"
                    label="Remember Device"
                    [value]="rememberDevice"
                    (valueChange)="setRememberDevice($event)">
                </cc-checkbox>
                <a id="forget-device-link" *ngIf="trustedDeviceEnabled && token && deviceIsTrusted" class="link-button fading" (click)="setRememberDevice(false)">Forget Device</a>
                <a id="forgot-password-link" class="link-button fading" href="{{forgotPasswordLink}}">Forgot Password</a>
            </div>
            <cc-button
                [type]="'outline'"
                [tabIndex]="3"
                [behavior]="'submit'"
            >Continue
            </cc-button>
        </div>
    </form>
</div>

<ng-container *ngIf="maintenanceWindow$ | async as maintenanceWindow">
    <ng-container *ngIf="maintenanceWindow.isActive">
        <app-maintenance-warning [maintenanceWindow]="maintenanceWindow"></app-maintenance-warning>
    </ng-container>
</ng-container>
