import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { IpWhitelistingEntry, WhitelistEntry } from 'app/admin/store/ip-whitelisting/ip-whitelisting.state';
import { IpWhitelistingOperationInfo } from 'app/core/services/ip-whitelisting/dto';

export enum IpWhitelistingActionTypes {
    SetRequestInFlight = '[IpWhitelisting] Set Request In Flight',
    FetchClientList = '[IpWhitelisting] Fetch Client List',
    ClientListFetched = '[IpWhitelisting] Client List Fetched',
    SelectClient = '[IpWhitelisting] Select Client',
    ClientSelected = '[IpWhitelisting] Client Selected',
    AddWhitelistedIpForClient = '[IpWhitelisting] Add Whitelisted IP for Client',
    RemoveWhitelistedIpForClient = '[IpWhitelisting] Remove Whitelisted IP for Client',
    NetworkCallFailed = '[IpWhitelisting] Network Call Failed',
    WhitelistedIpsForClientUpdated = '[IpWhitelisting] Whitelisted IPs for Client Updated',
    SetWhitelistingEnabledForClient = '[IpWhitelisting] Set Whitelisting Enabled for Client',
    UpdateWhitelistedIpForClient = '[IpWhitelisting] Update whitelisted IP for Client',
    WhitelistedIpForClientUpdated = '[IpWhitelisting] Whitelisted IP for Client Updated',
    FetchUserPermissions = '[IpWhitelisting] Fetch User Permissions',
    UserPermissionsFetched = '[IpWhitelisting] User Permissions Fetched',
}

export const setRequestInFlight = createAction(IpWhitelistingActionTypes.SetRequestInFlight, props<{ requestInFlight: boolean }>());

export const fetchClientList = createAction(IpWhitelistingActionTypes.FetchClientList);

export const clientListFetched = createAction(IpWhitelistingActionTypes.ClientListFetched, props<{ clientList: string[] }>());

export const networkCallFailed = createAction(IpWhitelistingActionTypes.NetworkCallFailed, props<{ error: HttpErrorResponse }>());

export const selectClientToManage = createAction(IpWhitelistingActionTypes.SelectClient, props<{ clientCode: string }>());

export const clientSelected = createAction(IpWhitelistingActionTypes.ClientSelected, props<{
    clientCode: string;
    whitelistingEntry: IpWhitelistingEntry;
    operationLog: IpWhitelistingOperationInfo[];
}>());

export const addWhitelistedIpForClient = createAction(IpWhitelistingActionTypes.AddWhitelistedIpForClient, props<{
    clientCode: string;
    whitelistEntry: WhitelistEntry;
}>());

export const removeWhitelistedIpForClient = createAction(IpWhitelistingActionTypes.RemoveWhitelistedIpForClient, props<{
    clientCode: string;
    whitelistEntry: WhitelistEntry;
}>());

export const updateWhitelistedIpForClient = createAction(IpWhitelistingActionTypes.UpdateWhitelistedIpForClient, props<{
    clientCode: string;
    whitelistEntry: WhitelistEntry;
}>());

export const whitelistedIpsForClientUpdated = createAction(IpWhitelistingActionTypes.WhitelistedIpsForClientUpdated, props<{
    updatedEntry: IpWhitelistingEntry;
    updatedLog: IpWhitelistingOperationInfo[];
}>());

export const setWhitelistingEnabledForClient = createAction(IpWhitelistingActionTypes.SetWhitelistingEnabledForClient, props<{
    clientCode: string;
    enabled: boolean;
}>());

export const fetchUserPermissions = createAction(IpWhitelistingActionTypes.FetchUserPermissions);

export const userPermissionsFetched = createAction(IpWhitelistingActionTypes.UserPermissionsFetched, props<{
    canManageIpWhitelisting: boolean;
}>());

export const whitelistedIpForClientUpdated = createAction(IpWhitelistingActionTypes.WhitelistedIpForClientUpdated, props<{
    updatedEntry: IpWhitelistingEntry;
    updatedLog: IpWhitelistingOperationInfo[];
}>());
