import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CcLayoutModule } from '@hs/ui-core-layout';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AdminModule } from 'app/admin/admin.module';
import { AppRoutingModule } from 'app/app-routing.module';
import { AppComponent } from 'app/app.component';
import { AuthModule } from 'app/auth/auth.module';
import { HedgeServHeadersInterceptor } from 'app/shared/http-interceptors/hedgeserv-headers.interceptor';
import { SharedModule } from 'app/shared/shared.module';
import { EnvironmentService } from 'environments/environment.service';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AuthModule,
        AdminModule,
        AppRoutingModule,
        HttpClientModule,
        SharedModule,
        StoreModule.forRoot({}),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument({ maxAge: 100 }),
        CcLayoutModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        EnvironmentService,
        { provide: HTTP_INTERCEPTORS, useClass: HedgeServHeadersInterceptor, multi: true },
    ],
})
export class AppModule {}
