import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
    removeWhitelistedIpForClient,
    updateWhitelistedIpForClient,
} from 'app/admin/store/ip-whitelisting/ip-whitelisting.actions';
import { selectRequestInFlight } from 'app/admin/store/ip-whitelisting/ip-whitelisting.selectors';
import { IpType, WhitelistEntry } from 'app/admin/store/ip-whitelisting/ip-whitelisting.state';
import { AuthState } from 'app/auth/store';

@Component({
    selector: 'app-ip-whitelisting-entry',
    templateUrl: './ip-whitelisting-entry.component.html',
    styleUrls: ['./ip-whitelisting-entry.component.scss'],
})
export class IpWhitelistingEntryComponent implements OnInit {
    @Input() ipEntry: WhitelistEntry;
    @Input() options: string[];
    @Input() clientCode: string;
    @Input() readOnly: boolean;

    requestInFlight$: Observable<boolean>;

    ipAddress: string = '';
    selectedType: IpType | null = null;
    comment: string = '';

    constructor(private readonly store: Store<AuthState>) {
        this.requestInFlight$ = this.store.select(selectRequestInFlight);
    }

    ngOnInit(): void {
        const { ipAddress, comment = '', type = null } = this.ipEntry;
        this.ipAddress = ipAddress;
        this.comment = comment;
        this.selectedType = type;
    }

    get areChangesPending(): boolean {
        return this.ipEntry.type !== this.selectedType || this.ipEntry.comment !== this.comment;
    }

    public onUpdateComment(value: string): void {
        this.comment = value;
    }

    public onSubmit(): void {
        if (this.selectedType) {
            this.store.dispatch(updateWhitelistedIpForClient({
                clientCode: this.clientCode,
                whitelistEntry: {
                    ipAddress: this.ipEntry.ipAddress,
                    type: this.selectedType,
                    comment: this.comment,
                },
            }));
        } else {
            alert('The Type field is required. Please select a value');
        }
    }

    public onWhitelistEntryRemoved(): void {
        this.store.dispatch(removeWhitelistedIpForClient({ clientCode: this.clientCode, whitelistEntry: this.ipEntry }));
    }
}
