import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CcDropdownsModule } from '@hs/ui-core-dropdowns';
import { CcInputsModule } from '@hs/ui-core-inputs';
import { CcNavbarModule } from '@hs/ui-core-navbar';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AdminRoutingModule } from 'app/admin/admin-routing.module';
import { AdminComponent } from 'app/admin/admin.component';
import { IpWhitelistingEffects } from 'app/admin/store/ip-whitelisting/ip-whitelisting.effects';
import { ipWhitelistingKey, ipWhitelistingReducer } from 'app/admin/store/ip-whitelisting/ip-whitelisting.reducer';
import { SharedModule } from 'app/shared/shared.module';

import { IpWhitelistingEntryComponent } from './containers/ip-whitelisting-entry/ip-whitelisting-entry.component';
import { IpWhitelistingManagementComponent } from './containers/ip-whitelisting-management/ip-whitelisting-management.component';

@NgModule({
    declarations: [
        AdminComponent,
        IpWhitelistingManagementComponent,
        IpWhitelistingEntryComponent,
    ],
    imports: [
        FormsModule,
        CommonModule,
        AdminRoutingModule,
        SharedModule,
        NgSelectModule,
        CcInputsModule,
        CcDropdownsModule,
        CcNavbarModule,
        StoreModule.forFeature(ipWhitelistingKey, ipWhitelistingReducer),
        EffectsModule.forFeature([IpWhitelistingEffects]),
        ReactiveFormsModule,
    ],
})
export class AdminModule {
}
