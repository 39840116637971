export function phoneNumberMask(value: string): string {
    try {
        return value.replace(/^.*?(\([X\d\s+-]+\)).*?$/i, '$1')
            .split('')
            .map((char, index, arr) => (/\d/.test(char) && index < arr.length - 3) ? 'X' : char)
            .join('');
    } catch (e) {
        return value;
    }
}
