/* eslint-disable no-console */
import { Logger } from './logger';

export class LoggerService implements Logger {
    debug(message: unknown, ...optionalParams: unknown[]): void {
        console.debug(message, ...optionalParams);
    }

    error(message: unknown, ...optionalParams: unknown[]): void {
        console.error(message, ...optionalParams);
    }

    log(message: unknown, ...optionalParams: unknown[]): void {
        console.log(message, ...optionalParams);
    }

    warn(message: unknown, ...optionalParams: unknown[]): void {
        console.warn(message, ...optionalParams);
    }
}
