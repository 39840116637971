<div class="app-input">
    <div class="input-header">
        <label [for]="name">
            <span class="label-text">{{ label }}</span>
        </label>
        <div>
            <ng-content></ng-content>
        </div>
    </div>
    <div class="input-main">
        <input
            #input
            class="input-field"
            [placeholder]="placeholder"
            [type]=type
            [id]="name"
            [name]="name"
            [value]="value"
            [class.disabled]="disabled"
            (input)="valueChanged($event)"
            (change)="valueChanged($event)"
            (keyup)="valueChanged($event)"
            [tabIndex]="tabIndex"
        />
    </div>
</div>
