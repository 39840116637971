import { Action, createReducer, on } from '@ngrx/store';

import { clearMessage, setMessage } from './message.actions';
import { MessageState } from './message.state';

export const messageKey = 'message';

const initialState: MessageState = '';

const reducer = createReducer(
    initialState,
    on(clearMessage, () => initialState),
    on(setMessage, (state, { message }) => message),
);

export function messageReducer(state: MessageState | undefined, action: Action): MessageState {
    return reducer(state, action);
}
