import { createFeatureSelector, createSelector } from '@ngrx/store';

import { authKey } from 'app/auth/store/auth/auth.reducer';
import { AuthStatus } from 'app/auth/store/auth/auth.state';

export const selectAuth = createFeatureSelector<AuthStatus>(authKey);

export const selectRequestInFlight = createSelector(selectAuth, (auth: AuthStatus) => auth.requestInFlight);
export const selectCredentialsToken = createSelector(selectAuth, (auth: AuthStatus) => auth.credentialsToken);
export const selectTrustedDeviceEnabled = createSelector(selectAuth, (auth: AuthStatus) => auth.trustedDeviceEnabled);
export const selectDevices = createSelector(selectAuth, (auth: AuthStatus) => (auth.twoFactorUserStatus?.devices ?? []));
export const selectUsername = createSelector(selectAuth, (auth: AuthStatus) => auth.username);
