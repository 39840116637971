<div class="push">
    <p>Confirmation sent to your mobile device</p>
    <p *ngIf="!!timer">Expires in <span class="expiry-time">{{ timer }} second{{ timer === 1 ? '' : 's' }}</span></p>
    <cc-button
        [type]="'outline'"
        (click)="selectAlternativeMethod()"
        *ngIf="showAuthSelectionLink"
        >Select Alternative Method
    </cc-button>
</div>
