import { Component, ElementRef, forwardRef, HostBinding, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputComponent),
        multi: true,
    }],
})
export class InputComponent implements ControlValueAccessor {
    @Input() name: string;
    @Input() type: string;
    @Input() label: string;
    @Input() placeholder: string = '';
    @Input() tabIndex: number;
    @Input() @HostBinding('class.disabled') disabled: boolean = false;

    @ViewChild('input') input: ElementRef;

    value: string;

    onChange: (_: string | undefined) => void;
    onTouch: (_: unknown) => void;

    registerOnChange(fn: (_: string | undefined) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: (_: unknown) => void): void {
        this.onTouch = fn;
    }

    writeValue(value: string): void {
        this.value = value;
        if (this.input) {
            this.input.nativeElement.value = value;
        }
    }

    valueChanged(event: ProgressEvent<HTMLInputElement>): void {
        this.onChange(event.target?.value);
    }

    focus(): void {
        this.input.nativeElement.focus();
    }
}
