<div class="auth">
    <div class="header">
        <ng-container *ngTemplateOutlet="logo"></ng-container>
    </div>
    <div class="main">
        <div class="content">
            <p class="message">{{ message$ | async }}</p>
            <h1 class="title">Sign In</h1>
            <router-outlet></router-outlet>
        </div>
    </div>
    <div class="side-drop" [ngSwitch]="quoteIndex">
        <div class="quote" *ngSwitchCase="1">
            <p>Setting the benchmark,</p>
            <p><span class="hedgeserv-red">continuously</span></p>
        </div>
        <div class="quote" *ngSwitchCase="2">
            <p><span class="hedgeserv-red">Unique solutions</span></p>
            <p>for common problems</p>
        </div>
        <div class="quote" *ngSwitchCase="3">
            <p><span class="hedgeserv-red">Global reach,</span></p>
            <p>local impact</p>
        </div>
    </div>
</div>

<ng-template #logo>
    <svg class="logo" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
         x="0px" y="0px"
         width="94.625px" height="9px" viewBox="0 0 94.625 9" enable-background="new 0 0 94.625 9"
     >
        <g>
            <polygon fill="#CC1F40" points="8.483,0.486 8.483,3.469 3.085,3.469 3.085,0.486 1.144,0.486 1.144,8.449 3.085,8.449
                3.085,5.127 8.483,5.127 8.483,8.449 10.424,8.449 10.424,0.486 	"/>
            <polygon fill="#CC1F40" points="14.462,6.824 14.462,5.127 18.133,5.127 18.133,3.488 14.462,3.488 14.462,2.109 20.555,2.109
                20.555,0.468 12.522,0.468 12.522,8.449 20.626,8.449 20.626,6.824 	"/>
            <path fill="#CC1F40" d="M29.981,1.087c-0.656-0.417-1.68-0.619-3.127-0.619h-4.553v7.981h4.553c3.229,0,4.866-1.35,4.866-4.012
                C31.721,2.942,31.134,1.815,29.981,1.087z M24.242,2.109h2.452c1.097,0,1.889,0.174,2.357,0.515
                c0.492,0.349,0.729,0.938,0.729,1.807c0,0.893-0.231,1.513-0.689,1.843c-0.482,0.354-1.288,0.531-2.397,0.531h-2.452V2.109z"/>
            <path fill="#CC1F40" d="M42.33,2.837V2.402c0-0.709-0.169-1.217-0.503-1.508c-0.329-0.286-0.85-0.426-1.592-0.426h-5.036
                c-0.782,0-1.314,0.147-1.629,0.451c-0.313,0.307-0.467,0.826-0.467,1.589v3.916c0,0.762,0.15,1.277,0.458,1.576
                c0.306,0.301,0.841,0.449,1.638,0.449h5.036c0.783,0,1.315-0.146,1.626-0.445c0.315-0.301,0.469-0.818,0.469-1.58V3.75h-4.978
                v1.553h3.039v1.501h-5.347V2.109h5.347v1.068L42.33,2.837z"/>
            <polygon fill="#CC1F40" points="46.083,6.824 46.083,5.127 49.753,5.127 49.753,3.488 46.083,3.488 46.083,2.109 52.176,2.109
                52.176,0.468 44.144,0.468 44.144,8.449 52.246,8.449 52.246,6.824 	"/>
            <path fill="#7C7C81" d="M61.903,3.934c-0.308-0.3-0.836-0.446-1.619-0.446h-4.51V2.109h4.681v1.048l1.816-0.396V2.368
                c0-0.697-0.169-1.197-0.506-1.485c-0.328-0.279-0.847-0.414-1.587-0.414h-4.145c-0.782,0-1.31,0.146-1.617,0.443
                c-0.31,0.301-0.457,0.824-0.457,1.597v0.701c0,0.765,0.147,1.278,0.457,1.579c0.306,0.3,0.835,0.444,1.617,0.444h4.526v1.572
                h-5.084V5.639l-1.817,0.373v0.432c0,0.754,0.149,1.266,0.458,1.566c0.308,0.295,0.842,0.439,1.636,0.439h4.531
                c0.782,0,1.312-0.148,1.618-0.449c0.308-0.301,0.458-0.818,0.458-1.584V5.518C62.36,4.753,62.21,4.236,61.903,3.934z"/>
            <polygon fill="#7C7C81" points="66.226,6.824 66.226,5.127 69.898,5.127 69.898,3.488 66.226,3.488 66.226,2.109 72.317,2.109
                72.317,0.468 64.288,0.468 64.288,8.449 72.389,8.449 72.389,6.824 	"/>
            <path fill="#7C7C81" d="M80.312,5.533h0.283c1.514,0,1.832-0.918,1.832-1.691V2.174c0-0.778-0.318-1.706-1.832-1.706h-6.268v7.981
                h1.938V5.533h1.665l2.975,2.916h2.693L80.312,5.533z M76.265,2.109h3.699c0.437,0,0.526,0.185,0.526,0.481v0.888
                c0,0.235-0.039,0.329-0.056,0.363c-0.034,0.058-0.173,0.121-0.471,0.121h-3.699V2.109z"/>
            <polygon fill="#7C7C81" points="91.48,0.468 88.441,6.162 85.27,0.468 83.138,0.468 87.653,8.314 87.73,8.449 89.114,8.449
                93.481,0.468 	"/>
        </g>
    </svg>
</ng-template>
