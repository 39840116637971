import { createAction, props } from '@ngrx/store';

import { TwoFactorUserStatus } from 'app/auth/store/auth/auth.state';
import { AuthMethod } from 'app/auth/store/auth-method/auth-method.state';
import { PasswordChangeRequestDTO } from 'app/core/services/auth/dto';

export enum AuthActionTypes {
    SetCredentialsToken = '[Auth] Set Credentials Token',
    ResetLoginState = '[Auth] Reset Login State',
    SetRequestInFlight = '[Auth] Set Request In Flight',

    SetTwoFactorUserStatus = '[Auth] Set TwoFactor User Status',
    ClearTwoFactorUserStatus = '[Auth] Clear TwoFactor User Status',
    SetTrustedDeviceToken = '[Auth] Set Trusted Device Token',
    ClearTrustedDeviceToken = '[Auth] Clear Trusted Device Token',

    LoginSetup = '[Auth] Login Setup',
    LoginAuthenticate = '[Auth] Login Authenticate',
    Logout = '[Auth] Logout',
    ChangePassword = '[Auth] Change Password',

    SendTwoFactorNotification = '[Auth] Send TwoFactor Notification',
    FetchDevices = '[Auth] Fetch Devices',
    FetchTwoFactorUserStatus = '[Auth] Fetch TwoFactor User Status',
    FetchTwoFactorNotificationStatus = '[Auth] Check TwoFactor Notification Status',
    VerifyPasscode = '[Auth] Verify Dasscode',
}

export const setCredentialsToken = createAction(
    AuthActionTypes.SetCredentialsToken,
    props<{ credentialsToken: string, trustedDeviceEnabled: boolean }>(),
);
export const resetLoginState = createAction(AuthActionTypes.ResetLoginState);
export const setRequestInFlight = createAction(AuthActionTypes.SetRequestInFlight, props<{ requestInFlight: boolean }>());
export const setTwoFactorUserStatus = createAction(
    AuthActionTypes.SetTwoFactorUserStatus,
    props<{ twoFactorUserStatus: TwoFactorUserStatus }>(),
);
export const clearTwoFactorUserStatus = createAction(AuthActionTypes.ClearTwoFactorUserStatus);
export const setTrustedDeviceToken = createAction(AuthActionTypes.SetTrustedDeviceToken, props<{ token: string }>());
export const clearTrustedDeviceToken = createAction(AuthActionTypes.ClearTrustedDeviceToken);

export const loginSetup = createAction(AuthActionTypes.LoginSetup, props<{ username: string }>());
export const loginAuthenticate = createAction(AuthActionTypes.LoginAuthenticate, props<{ password: string, rememberDevice: boolean }>());

export const logout = createAction(AuthActionTypes.Logout);
export const changePassword = createAction(AuthActionTypes.ChangePassword, props<PasswordChangeRequestDTO>());

export const sendTwoFactorNotification = createAction(AuthActionTypes.SendTwoFactorNotification, props<{ authMethod: AuthMethod }>());
export const fetchDevices = createAction(AuthActionTypes.FetchDevices);
export const fetchTwoFactorUserStatus = createAction(AuthActionTypes.FetchTwoFactorUserStatus);
export const checkTwoFactorNotificationStatus = createAction(AuthActionTypes.FetchTwoFactorNotificationStatus);
export const verifyPasscode = createAction(AuthActionTypes.VerifyPasscode, props<{ passcode: string }>());
