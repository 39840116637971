import { Component } from '@angular/core';

@Component({
    selector: 'admin-root',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
})
export class AdminComponent {
    protected appName: string = 'WAUTH ADMIN';
}
