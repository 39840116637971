import { Component, Input } from '@angular/core';

import { MaintenanceWindow } from 'app/auth/store/maintenance/maintenance.state';

@Component({
    selector: 'app-maintenance-warning',
    templateUrl: './maintenance-warning.component.html',
    styleUrls: ['./maintenance-warning.component.scss'],
})
export class MaintenanceWarningComponent {
    @Input() public maintenanceWindow: MaintenanceWindow;
}
