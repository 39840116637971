import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { logout } from 'app/auth/store/auth/auth.actions';

@Component({
    selector: 'app-logout-page',
    template: '',
})
export class LogoutComponent implements OnInit {
    constructor(
        private readonly store: Store,
    ) {}

    ngOnInit(): void {
        this.store.dispatch(logout());
    }
}
