<div class="ip-whitelisting-management" *ngFor="let canBeManaged of [canBeManaged$ | async]">
    <div class="client-selection-container">
        <div class="ip-whitelisting-client-select">
            <ng-select
                id="client-selection"
                class="client-selector"
                (change)="onClientSelected($event)"
                [searchable]="true"
                [searchFn]="searchClientList"
                [clearable]="false"
                [items]="clientList$ | async"
                [readonly]="requestInFlight$ | async"
                placeholder="Select client"
                bindLabel="text"
            ></ng-select>
        </div>
        <div class="ip-whitelisting-btn-export">
            <cc-button (click)="downloadCsv()">EXPORT</cc-button>
        </div>
    </div>

    <div class="ip-whitelisting-container">
        <ng-container *ngIf="selectedClient$ | async as selectedClient">
            <cc-option-toggle
                #optionToggle
                [value]="selectedClient?.whitelistingEntry.enabled"
                (valueChange)="onOptionToggled(selectedClient, optionToggle, $event)"
                [labels]="['Whitelist Enabled', 'Whitelist Disabled']"
                [disabled]="!canBeManaged || (requestInFlight$ | async)"
            >
            </cc-option-toggle>
            <form
                class="add-ip"
                [formGroup]="entryForm"
                (ngSubmit)="onWhitelistEntryAdded(selectedClient?.clientCode)">
                <app-input
                    ngDefaultControl
                    class="ip-input form-control"
                    label="IP Address"
                    placeholder="Add New"
                    formControlName="ipAddress"
                    [disabled]="!canBeManaged"
                ></app-input>
                <div class="type-select-wrapper">
                    <label for="type-selection">Type</label>
                    <ng-select
                        ngDefaultControl
                        id="type-selection"
                        class="type-select form-control"
                        [searchable]="false"
                        [clearable]="false"
                        [items]="options"
                        placeholder="Select one"
                        bindLabel="text"
                        formControlName="type"
                        [readonly]="!canBeManaged"
                        [ngClass]="{'required' : entryForm.controls['ipAddress'].touched ||
                        entryForm.controls['type'].touched || entryForm.controls['type'].dirty}"
                    ></ng-select>
                </div>
                <app-input
                    ngDefaultControl
                    class="comment-input form-control"
                    type="text"
                    name="comment"
                    label="Comment"
                    placeholder="Add New"
                    formControlName="comment"
                    [disabled]="!canBeManaged"
                ></app-input>
                <div class="add-button-right">
                    <cc-button
                        class="add-entry"
                        [type]="'naked'"
                        [behavior]="'submit'"
                        [disabled]="!canBeManaged || areChangesPending || (requestInFlight$ | async)"
                    >Add
                    </cc-button>
                </div>
            </form>
            <app-ip-whitelisting-entry
                class="whitelisted-ips"
                *ngFor="let ipEntry of selectedClient?.whitelistingEntry.ips"
                [ipEntry]="ipEntry"
                [clientCode]="selectedClient?.clientCode"
                [options]="options"
                [readOnly]="!canBeManaged"
            ></app-ip-whitelisting-entry>
        </ng-container>
    </div>

    <div class="audit-log">
        <h2>Audit log</h2>
        <div class="audit-log-table-container" *ngIf="selectedClient$ | async as selectedClient">
            <table>
                <tr class="operation" *ngFor="let operation of selectedClient.operationLog">
                    <td class="ip">{{ operation.body }}</td>
                    <td class="date">
                        {{
                            operation.type === 'ADD' ? 'Added' :
                            operation.type === 'REMOVE' ? 'Deleted' : 'Set'
                        }}
                        {{
                            operation.date | date:'YYYY-MM-d h:mm a'
                        }}
                    </td>
                    <td class="username">{{ operation.user.username | uppercase }}</td>
                </tr>
            </table>
        </div>
    </div>
</div>
