import { DOCUMENT } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren,
    ViewRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, fromEvent, merge, Observable, Subscription } from 'rxjs';
import { debounce, filter, take } from 'rxjs/operators';

import { AuthState } from 'app/auth/store';
import {
    resetLoginState,
    clearTrustedDeviceToken,
    loginAuthenticate,
    loginSetup,
} from 'app/auth/store/auth/auth.actions';
import {
    selectRequestInFlight,
    selectCredentialsToken,
    selectUsername,
    selectTrustedDeviceEnabled,
} from 'app/auth/store/auth/auth.selectors';
import { fetchMaintenanceWindowRequest } from 'app/auth/store/maintenance/maintenance.actions';
import * as fromMaintenanceSelector from 'app/auth/store/maintenance/maintenance.selector';
import { MaintenanceWindow } from 'app/auth/store/maintenance/maintenance.state';
import { clearMessage, setMessage } from 'app/auth/store/message';
import { AuthService } from 'app/core/services/auth/auth.service';
import { InputComponent } from 'app/shared/components/input/input.component';
import { EnvironmentService } from 'environments/environment.service';

import { INVALID_EMAIL_ADDRESS } from '../../errors/messages';

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChildren('userInput') private readonly usernameInput: QueryList<InputComponent>;
    @ViewChildren('passInput') private readonly passwordInput: QueryList<InputComponent>;

    private usernameSubscription: Subscription;
    private credentialsTokenSubscription: Subscription;
    private requestInFlightSubscription: Subscription;
    private inputChangesSubscription: Subscription;
    private enterKeyStateSubscription: Subscription;
    private trustedDeviceTokenSubscription: Subscription;
    private showTrustedDeviceEnabledSubscription: Subscription;

    private enterKeyIsReleased$: BehaviorSubject<boolean>;

    public maintenanceWindow$: Observable<MaintenanceWindow | null>;

    loginForm: FormGroup;

    username: string | null = null;
    token: string | null = null;
    // these are similar, but not the same - rememberDevice indicates that we want to remember the device, device is trusted indicates that
    // we already have a trusted device token (meaning that we've already logged in with rememberDevice set to true)
    rememberDevice: boolean = false;
    deviceIsTrusted: boolean = false;
    trustedDeviceEnabled: boolean = false;

    constructor(
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly store: Store<AuthState>,
        private readonly environment: EnvironmentService,
        private readonly authService: AuthService,
        private readonly formBuilder: FormBuilder,
        @Inject(DOCUMENT) private readonly document: Document,
    ) {
        this.loginForm = this.formBuilder.group({
            username: ['', [Validators.required, Validators.pattern(/@/)]],
            password: ['', [Validators.required]],
        });
    }

    ngOnInit(): void {
        this.maintenanceWindow$ = this.store.select(fromMaintenanceSelector.selectMaintenanceWindow);

        this.store.dispatch(fetchMaintenanceWindowRequest());

        this.enterKeyIsReleased$ = new BehaviorSubject<boolean>(true);
        this.enterKeyStateSubscription = merge(
            fromEvent(this.document, 'keydown'),
            fromEvent(this.document, 'keyup'),
        ).pipe(
            filter((event: Event) => (event as KeyboardEvent).key === 'Enter'),
        ).subscribe((event: Event) => this.enterKeyIsReleased$.next(event.type === 'keyup'));

        this.usernameSubscription = this.store.select(selectUsername).subscribe((username) => {
            this.username = username ?? null;

            this.trustedDeviceTokenSubscription?.unsubscribe();
            if (username) {
                this.trustedDeviceTokenSubscription = this.authService.getTrustedDeviceToken(username)
                    .subscribe((trustedDeviceToken) => {
                        this.deviceIsTrusted = !!trustedDeviceToken;
                        this.rememberDevice = this.deviceIsTrusted;
                    });
            } else {
                this.deviceIsTrusted = false;
                this.rememberDevice = false;
            }
        });

        this.credentialsTokenSubscription = this.store.select(selectCredentialsToken).subscribe((credentialsToken) => {
            if (!credentialsToken) {
                this.reset();
            }
            this.token = credentialsToken;
            this.afterEnterKeyIsReleased(() => this.passwordInput.first.focus());
        });

        this.requestInFlightSubscription = this.store.select(selectRequestInFlight).subscribe((requestInFlight) => {
            if (requestInFlight) {
                this.loginForm.disable();
            } else {
                this.loginForm.enable();
            }
        });

        this.showTrustedDeviceEnabledSubscription = this.store.select(selectTrustedDeviceEnabled).subscribe((trustedDeviceEnabled) => {
            this.trustedDeviceEnabled = trustedDeviceEnabled;
        });
    }

    ngAfterViewInit(): void {
        this.usernameInput?.first?.focus();
        this.inputChangesSubscription = merge(this.usernameInput.changes, this.passwordInput.changes)
            .pipe(
                filter((list) => !!list.first),
                debounce(() => this.enterKeyIsReleased$.pipe(filter((enterKeyReleased) => enterKeyReleased))),
            )
            .subscribe((list: QueryList<InputComponent>) => {
                if (list.length) {
                    list.first.focus();
                }
            });
    }

    ngOnDestroy(): void {
        this.requestInFlightSubscription?.unsubscribe();
        this.credentialsTokenSubscription?.unsubscribe();
        this.inputChangesSubscription?.unsubscribe();
        this.enterKeyStateSubscription?.unsubscribe();
        this.trustedDeviceTokenSubscription?.unsubscribe();
        this.usernameSubscription?.unsubscribe();
        this.showTrustedDeviceEnabledSubscription?.unsubscribe();
    }

    get isFormValid(): boolean {
        if (this.token) {
            return this.loginForm.controls.password.valid;
        } else {
            return this.loginForm.controls.username.valid;
        }
    }

    get forgotPasswordLink(): string {
        return this.environment.production ? 'https://clientselfservice.hedgeserv.com' : 'https://selfservice.hedgeserv.com';
    }

    public submit(): void {
        if (!this.isFormValid) {
            if (!this.token && this.loginForm.controls.username.invalid) {
                this.store.dispatch(setMessage({ message: INVALID_EMAIL_ADDRESS }));
            }
            return;
        } else if (this.token) {
            this.authenticate();
        } else {
            this.setThisUsername();
        }
    }

    public reset(): void {
        this.store.dispatch(resetLoginState());
        this.loginForm.reset();
        this.afterEnterKeyIsReleased(() => this.usernameInput.first.focus());
    }

    public setRememberDevice(rememberDevice: boolean): void {
        this.rememberDevice = rememberDevice;
        if (!this.rememberDevice) {
            this.store.dispatch(clearTrustedDeviceToken());
        }
    }

    private afterEnterKeyIsReleased(callback: () => void): void {
        if (!(this.changeDetectorRef as ViewRef).destroyed) {
            this.changeDetectorRef.detectChanges();
        }

        this.enterKeyIsReleased$
            .pipe(
                filter((enterKeyReleased: boolean) => enterKeyReleased),
                take(1),
            ).subscribe(callback);
    }

    private authenticate(): void {
        this.store.dispatch(clearMessage());
        const password = this.loginForm.controls.password.value;
        this.store.dispatch(loginAuthenticate({
            password,
            rememberDevice: this.trustedDeviceEnabled && this.rememberDevice,
        }));
    }

    private setThisUsername(): void {
        const username = this.loginForm.controls.username.value.trim();
        this.store.dispatch(loginSetup({ username }));
    }
}
