import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MaintenanceWindowDto } from './dto';

@Injectable({
    providedIn: 'root',
})
export class MaintenanceService {
    constructor(
        private readonly http: HttpClient,
    ) {}

    public fetchMaintenanceWindow(): Observable<MaintenanceWindowDto> {
        return this.http.get<MaintenanceWindowDto>('api/maintenance/window');
    }
}
