import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class HedgeServHeadersInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const reqWithHeaders = req.clone({
            headers: req.headers.set('x-hedgeserv-initiator', 'webauth'),
        });

        return next.handle(reqWithHeaders);
    }
}
