import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthState } from 'app/auth/store';

import { selectMessage } from './store/message';

@Component({
    selector: 'auth-root',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
    message$: Observable<string>;
    quoteIndex: number;

    constructor(
        private readonly store: Store<AuthState>,
    ) {
        this.quoteIndex = Math.ceil(Math.random() * 3);
    }

    ngOnInit(): void {
        this.message$ = this.store.select(selectMessage)
            .pipe(map((message) => message.replace(/\.$/, '')));
    }
}
