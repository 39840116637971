import { Action, createReducer, on } from '@ngrx/store';

import {
    resetLoginState,
    setRequestInFlight,
    setCredentialsToken,
    setTwoFactorUserStatus,
    clearTwoFactorUserStatus,
    loginSetup,
} from 'app/auth/store/auth/auth.actions';
import { AuthStatus } from 'app/auth/store/auth/auth.state';

export const authKey = 'auth';

const initialState: AuthStatus = {
    username: null,
    credentialsToken: null,
    requestInFlight: false,
    twoFactorUserStatus: null,
    trustedDeviceEnabled: false,
};

const reducer = createReducer(
    initialState,
    on(setCredentialsToken, (state, { credentialsToken, trustedDeviceEnabled }) => ({ ...state, credentialsToken, trustedDeviceEnabled })),
    on(resetLoginState, (state) => ({ ...state, credentialsToken: null, username: null })),
    on(setRequestInFlight, (state, { requestInFlight }) => ({ ...state, requestInFlight })),
    on(setTwoFactorUserStatus, (state, { twoFactorUserStatus }) => ({ ...state, twoFactorUserStatus })),
    on(clearTwoFactorUserStatus, (state) => ({ ...state, twoFactorUserStatus: null })),
    on(loginSetup, (state, { username }) => ({ ...state, username })),
);

export function authReducer(state: AuthStatus | undefined, action: Action): AuthStatus {
    return reducer(state, action);
}
