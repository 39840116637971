import { Injectable } from '@angular/core';
import { ChildActivationStart, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { defer, Observable } from 'rxjs';
import { filter, switchMapTo, take, tap } from 'rxjs/operators';

import { AuthState } from 'app/auth/store';
import { setUrl } from 'app/auth/store/redirect';
import { Logger } from 'app/shared/services/logging/logger';
import { NamedLogger } from 'app/shared/utils/logging/named-logger';

import { RedirectActionTypes } from './redirect.actions';
import { selectRedirectUrl } from './redirect.selectors';

@Injectable()
export class RedirectEffects extends NamedLogger {
    init$: Observable<ChildActivationStart> = createEffect(() => {
        return defer(() => this.router.events.pipe(
            filter((event): event is ChildActivationStart => event instanceof ChildActivationStart),
            take(1),
            tap((activationStart: ChildActivationStart) => {
                const redirectFragment = activationStart.snapshot.fragment;
                let redirectUrl = activationStart.snapshot.queryParams.continue_url as string;
                if (redirectUrl) {
                    if (redirectFragment) {
                        redirectUrl += `#${redirectFragment}`;
                    }
                    this.logInfo('Redirect URL:', redirectUrl);
                    this.store.dispatch(setUrl({ url: redirectUrl }));
                }
            })));
    }, { dispatch: false });

    redirectTo$: Observable<string> = createEffect(() => {
        return this.actions$.pipe(
            ofType(RedirectActionTypes.Redirect),
            switchMapTo(this.store.select(selectRedirectUrl)),
            tap((redirectLocation) => {
                this.logInfo('Redirecting to', redirectLocation);
                window.location.href = redirectLocation;
            }));
    }, { dispatch: false },
    );

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<AuthState>,
        private readonly router: Router,
        logger: Logger,
    ) {
        super(logger);
    }
}
