import { createFeatureSelector, createSelector } from '@ngrx/store';

import { sessionKey } from 'app/auth/store/session/session.reducer';
import { Session } from 'app/auth/store/session/session.state';

export const selectSession = createFeatureSelector<Session>(sessionKey);

export const selectIsLoggedIn = createSelector(
    selectSession,
    (state) => {
        if (state === undefined) {
            return undefined;
        } else if (state == null) {
            return false;
        } else {
            return state.isLoggedIn;
        }
    });
