import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ipWhitelistingKey } from 'app/admin/store/ip-whitelisting/ip-whitelisting.reducer';
import { IpWhitelistingState } from 'app/admin/store/ip-whitelisting/ip-whitelisting.state';

export const selectIpWhitelisting = createFeatureSelector<IpWhitelistingState>(ipWhitelistingKey);

export const selectRequestInFlight = createSelector(selectIpWhitelisting, (state: IpWhitelistingState) => state.requestInFlight);
export const selectSelectedClient = createSelector(selectIpWhitelisting, (state: IpWhitelistingState) => state.selectedClient);
export const selectClientList = createSelector(selectIpWhitelisting, (state: IpWhitelistingState) => state.clientList);
export const selectIsMaintainer = createSelector(selectIpWhitelisting, (state: IpWhitelistingState) => state.isMaintainer);

export const selectWhitelistedEntryForUpdate = createSelector(
    selectIpWhitelisting, (state: IpWhitelistingState) => state.selectedClient?.whitelistingEntry,
);
