import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { AuthState } from 'app/auth/store';
import { selectSession } from 'app/auth/store/session/session.selectors';
import { Logger } from 'app/shared/services/logging/logger';
import { NamedLogger } from 'app/shared/utils/logging/named-logger';

@Injectable({
    providedIn: 'root',
})
export class PasswordChangeGuard extends NamedLogger implements CanActivate {
    constructor(
        logger: Logger,
        private readonly store: Store<AuthState>,
        private readonly router: Router,
    ) {
        super(logger);
    }

    canActivate(): Observable<boolean> {
        return this.store.select(selectSession)
            .pipe(
                filter((session) => session !== undefined),
                tap((session) => this.logDebug('Session:', session)),
                map((session?) =>
                    !!session?.isLoggedIn ||
                    !!session?.credentialsAuthenticated && session.passwordChangeRequired,
                ),
                tap((canAccessPasswordChangeRoute) => {
                    this.logDebug('Can access password change page?', canAccessPasswordChangeRoute);
                    if (!canAccessPasswordChangeRoute) {
                        this.router.navigate(['/login']).then(
                            (result) => this.logDebug('Navigation succeeded?', result),
                            (error) => this.logError('Navigation to /login failed:', error));
                    }
                }),
            );
    }
}
