import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { AuthState } from 'app/auth/store';
import { fetchDevices, sendTwoFactorNotification } from 'app/auth/store/auth/auth.actions';
import { selectDevices, selectRequestInFlight } from 'app/auth/store/auth/auth.selectors';
import { Device, DeviceCapability } from 'app/auth/store/auth/auth.state';
import { selectFailure } from 'app/auth/store/failure';
import { phoneNumberMask } from 'app/auth/utils/phone-number-mask';

type CapabilityMap = { [key in Exclude<DeviceCapability, 'auto'>]: number } & { auto: null };

@Component({
    selector: 'app-authentication-selection',
    templateUrl: './authentication-selection.component.html',
    styleUrls: ['./authentication-selection.component.scss'],
})
export class AuthenticationSelectionComponent implements OnInit, OnDestroy {
    deviceSubscription: Subscription;
    options: AuthenticationMethod[];
    selectedAuthenticationMethod: AuthenticationMethod;
    hasFailed$: Observable<boolean>;
    requestInFlight$: Observable<boolean>;

    constructor(
        private readonly store: Store<AuthState>,
    ) {
    }

    ngOnInit(): void {
        this.store.dispatch(fetchDevices());

        this.hasFailed$ = this.store.select(selectFailure);
        this.requestInFlight$ = this.store.select(selectRequestInFlight);

        this.deviceSubscription = this.store.select(selectDevices)
            .subscribe((devices: Device[]) => {
                this.populateDropdownWith(devices);
            });
    }

    ngOnDestroy(): void {
        this.deviceSubscription?.unsubscribe();
    }

    populateDropdownWith(devices: Device[]): void {
        this.options = devices.flatMap((device) => device.capabilities
            .filter((capability) => capability !== 'auto')
            .map((capability) => {
                const value = {
                    text: '',
                    factor: capability,
                    device,
                };

                switch (capability) {
                    case 'sms':
                        value.text = `SMS / Text ${phoneNumberMask(device.displayName)}`;
                        break;
                    case 'call':
                        value.text = `Voice ${phoneNumberMask(device.displayName)}`;
                        break;
                    case 'push':
                        value.text = `Push to ${phoneNumberMask(device.displayName)}`;
                        break;
                    case 'otp':
                        value.text = `Passcode from ${phoneNumberMask(device.displayName)}`;
                        break;
                    case 'token_otp':
                        value.text = 'Passcode from token';
                        break;
                }

                return value;
            }))
            .sort(sortOptionsByCapability);
    }

    sendConfirmation(): void {
        this.store.dispatch(sendTwoFactorNotification({
            authMethod: {
                device: this.selectedAuthenticationMethod.device,
                factor: this.selectedAuthenticationMethod.factor,
            },
        }));
    }
}

function sortOptionsByCapability(a: AuthenticationMethod, b: AuthenticationMethod): number {
    const capabilityMap: CapabilityMap = {
        push: 0,
        sms: 1,
        call: 2,
        otp: 3,
        token_otp: 4,
        auto: null,
    };

    const capabilityA = capabilityMap[a.factor];
    const capabilityB = capabilityMap[b.factor];

    // the nulls should never happen, but who knows
    if (!capabilityA) {
        return -1000;
    } else if (!capabilityB) {
        return 1000;
    }

    return capabilityA - capabilityB;
}

interface AuthenticationMethod {
    text: string;
    device: Device;
    factor: DeviceCapability;
}
