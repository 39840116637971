<div class="whitelisted-ip-list-container">
    <form class="update-ip" (ngSubmit)="onSubmit()">
        <p class="ip-address-section">{{ ipAddress }}</p>
        <div class="type-select-wrapper">
            <ng-select
                id="type-selection"
                class="type-select form-control"
                name="type-selection"
                [(ngModel)]="selectedType"
                [searchable]="false"
                [clearable]="false"
                [items]="options"
                [readonly]="readOnly"
                placeholder="Select one"
                bindLabel="text"
            ></ng-select>
        </div>
        <cc-text-input
            [id]="'comment-input'"
            class="comment-input secondary-comment"
            placeholder="Add New"
            size="fit-to-parent"
            [value]="comment"
            [disabled]="readOnly"
            (valueChange)="onUpdateComment($event)"
        ></cc-text-input>

        <cc-button
            class="update-button"
            [type]="'naked'"
            [behavior]="'submit'"
            [disabled]="readOnly || !areChangesPending || (requestInFlight$ | async)"
        >Update
        </cc-button>
        <cc-button
            class="remove-ip"
            (click)="onWhitelistEntryRemoved()"
            [type]="'naked'"
            [disabled]="readOnly || (requestInFlight$ | async)"
        >Delete
        </cc-button>
    </form>
</div>
