<div class="passcode-entry-page">
    <p *ngIf="tickerSubscription">Expires in <span class="expiry-time">{{ timer }} second{{ timer === 1 ? '' : 's' }}</span></p>
    <form (ngSubmit)="verifyPasscode()" autocomplete="off">
        <p class="passcode-info-message">
            <span>{{ label }}</span>
            <a class="link-button" [routerLink]="['/authentication-selection']">Change</a>
        </p>
        <app-input
            #codeInput
            type="text"
            id="passcode"
            name="passcode"
            label="Enter Confirmation"
            placeholder="Enter Code"
            [(ngModel)]="passcode"
        ></app-input>
        <div class="actions">
            <cc-button
                [type]="'outline'"
                [behavior]="'submit'"
                [disabled]="!passcode || (requestInFlight$ | async)"
               >Sign In
            </cc-button>
        </div>
    </form>
    <app-need-help></app-need-help>
</div>
