import { Action, createReducer, on } from '@ngrx/store';

import { clearSession, sessionSet } from 'app/auth/store/session/session.actions';
import { SessionState } from 'app/auth/store/session/session.state';

export const sessionKey = 'session';

const initialState: SessionState = undefined;

const reducer = createReducer<SessionState>(
    initialState,
    on(sessionSet, (state, action) => ({ ...state, ...action.session })),
    on(clearSession, () => null),
);

export function sessionReducer(state: SessionState, action: Action): SessionState {
    return reducer(state, action);
}
