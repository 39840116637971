import { Logger } from 'app/shared/services/logging/logger';

export abstract class NamedLogger {
    private readonly uniqueName: string;

    protected constructor(private readonly logger: Logger) {
        this.uniqueName = `${this.constructor.name} ${Math.ceil(Math.random() * 100)}`;
    }

    protected logDebug(message: string, ...optionalParams: unknown[]): void {
        this.logger.debug(`${this.uniqueName} - ${message}`, ...optionalParams);
    }

    protected logError(message: string, ...optionalParams: unknown[]): void {
        this.logger.error(`${this.uniqueName} - ${message}`, ...optionalParams);
    }

    protected logInfo(message: string, ...optionalParams: unknown[]): void {
        this.logger.log(`${this.uniqueName} - ${message}`, ...optionalParams);
    }

    protected logWarning(message: string, ...optionalParams: unknown[]): void {
        this.logger.warn(`${this.uniqueName} - ${message}`, ...optionalParams);
    }
}
