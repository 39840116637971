import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AuthState } from 'app/auth/store';
import { changePassword } from 'app/auth/store/auth/auth.actions';
import { selectRequestInFlight } from 'app/auth/store/auth/auth.selectors';
import { PasswordChangeRequestDTO } from 'app/core/services/auth/dto';

@Component({
    selector: 'app-password-change-page',
    templateUrl: './password-change-page.component.html',
    styleUrls: ['./password-change-page.component.scss'],
})
export class PasswordChangePageComponent implements OnInit {
    requestInFlight$: Observable<boolean>;
    private readonly requiredPasswordMinLength: number = 16;
    protected oldPassword: string = '';
    protected newPassword: string = '';
    protected newPasswordConfirm: string = '';
    protected oldPasswordError: string = '';
    protected newPasswordError: string = '';
    protected confirmPasswordError: string = '';
    protected complexityErrors: number = 0;

    constructor(
        formBuilder: FormBuilder,
        private readonly store: Store<AuthState>,
    ) {}

    ngOnInit(): void {
        this.requestInFlight$ = this.store.select(selectRequestInFlight);
    }

    onPasswordChangeSubmit(): void {
        this.checkOldPassword();
        this.newPasswordsMatch();
        this.passwordsComplexityCheck();
        if (!this.validatorsFail()) {
            const passwordChangeData = {
                oldPassword: this.oldPassword,
                newPassword: this.newPassword,
            };
            this.changePassword(passwordChangeData);
        }
    }

    protected onNewPasswordChange(newValue: string): void {
        this.newPassword = newValue;
    }

    protected onOldPasswordChange(newValue: string): void {
        this.oldPassword = newValue;
    }

    protected onConfirmPasswordChange(newValue: string): void {
        this.passwordsComplexityCheck();
        this.newPasswordConfirm = newValue;
        this.newPasswordsMatch();
    }

    private changePassword(passwordChangeData: PasswordChangeRequestDTO): void {
        this.store.dispatch(changePassword(passwordChangeData));
    }

    protected checkOldPassword(): void {
        if (this.oldPassword !== undefined && this.oldPassword.length < 1) {
            this.oldPasswordError = 'Please provide your old password';
            return;
        } else {
            this.oldPasswordError = '';
        }
        return;
    }

    protected newPasswordsMatch(): void {
        if (this.newPassword === undefined) {
            return;
        }
        if (this.newPassword !== this.newPasswordConfirm) {
            this.confirmPasswordError = 'Passwords do not match';
            return;
        } else {
            this.confirmPasswordError = '';
            return;
        }
    }

    protected passwordsComplexityCheck(): void {
        this.checkOldPassword();

        if (this.newPassword === '') {
            this.newPasswordError = '';
            return;
        }

        if (!this.isPasswordLongEnough(this.newPassword)) {
            this.newPasswordError = `Password must be at least ${this.requiredPasswordMinLength} characters`;
            return;
        }

        this.complexityErrors = 0;
        let lastComplexityError = '';
        if (!this.containsUppercaseCharacter(this.newPassword)) {
            lastComplexityError = 'Password must contain capital letters';
            this.complexityErrors += 1;
        }

        if (!this.containsLowercaseCharacter(this.newPassword)) {
            lastComplexityError = 'Password must contain lowercase letters';
            this.complexityErrors += 1;
        }

        if (!this.containsSpecialCharacter(this.newPassword)) {
            lastComplexityError = 'Password must contain special characters';
            this.complexityErrors += 1;
        }

        if (!this.containsNumber(this.newPassword)) {
            lastComplexityError = 'Password must contain a number';
            this.complexityErrors += 1;
        }

        if (this.complexityErrors > 1) {
            this.newPasswordError = lastComplexityError;
            return;
        }

        if (this.newPassword === this.oldPassword) {
            this.newPasswordError = 'New password must be different';
            return;
        } else {
            this.newPasswordError = '';
        }
    }

    protected validatorsFail(): boolean {
        return this.oldPassword === ''
            || this.newPassword === ''
            || !this.isPasswordLongEnough(this.newPassword)
            || this.newPasswordConfirm === ''
            || (this.confirmPasswordError.length > 0 && this.newPassword !== this.newPasswordConfirm)
            || this.complexityErrors > 1;
    }

    private isPasswordLongEnough(str: string): boolean {
        return str.length >= this.requiredPasswordMinLength;
    }

    private containsSpecialCharacter(str: string): boolean {
        return /[-!"#$%&()*,:;?@[^_`{|}~+<=>]/.test(str);
    }

    private containsUppercaseCharacter(str: string): boolean {
        return /[A-Z]/.test(str);
    }

    private containsLowercaseCharacter(str: string): boolean {
        return /[a-z]/.test(str);
    }

    private containsNumber(str: string): boolean {
        return /[\d]/.test(str);
    }
}
