import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthComponent } from 'app/auth/auth.component';
import { LoginPageGuard } from 'app/auth/guards/login-page.guard';
import { PasscodeGuard } from 'app/auth/guards/passcode.guard';
import { PasswordChangeGuard } from 'app/auth/guards/password-change.guard';
import { TwoFactorGuard } from 'app/auth/guards/two-factor.guard';

import { AuthenticationSelectionComponent } from './containers/authentication-selection/authentication-selection.component';
import { LoginPageComponent } from './containers/login-page/login-page.component';
import { LogoutComponent } from './containers/logout/logout.component';
import { PasscodeComponent } from './containers/passcode/passcode.component';
import { PasswordChangePageComponent } from './containers/password-change-page/password-change-page.component';
import { PushComponent } from './containers/push/push.component';

const routes: Routes = [
    { path: '', component: AuthComponent, children: [
        { path: '', pathMatch: 'full', redirectTo: 'login' },
        { path: 'login', component: LoginPageComponent, pathMatch: 'full', canActivate: [LoginPageGuard] },
        { path: 'logout', component: LogoutComponent, pathMatch: 'full' },
        { path: 'authentication-selection', component: AuthenticationSelectionComponent, pathMatch: 'full', canActivate: [TwoFactorGuard] },
        { path: 'passcode', component: PasscodeComponent, pathMatch: 'full', canActivate: [PasscodeGuard] },
        { path: 'push', component: PushComponent, pathMatch: 'full', canActivate: [TwoFactorGuard] },
        { path: 'change-password', component: PasswordChangePageComponent, pathMatch: 'full', canActivate: [PasswordChangeGuard] },
    ] },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthRoutingModule {
}
