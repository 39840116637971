import { HttpClient } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';

import { Logger } from './logger';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
    constructor(
        private readonly http: HttpClient,
        private readonly logger: Logger,
    ) {}

    handleError(error: unknown): void {
        this.logger.error(error);
        try {
            this.sendErrorToUsageTracking(error);
        } catch (e) {
            this.logger.error('Unable to report error', e);
        }
    }

    private sendErrorToUsageTracking(error: unknown): void {
        const { message, stack } = getErrorMessageAndStack(error);
        this.http.post('/errors', { message, stack }).subscribe(
            () => { /* success */ },
            (e) => {
                this.logger.error('Failed to report error:', e);
            });
    }
}

function getErrorMessageAndStack(err: unknown): { message: string, stack: string | null } {
    let message: string;
    let stack: string | null = null;

    if (err instanceof Error) {
        message = err.message;
        stack = err.stack ?? null;
    } else if (Object.prototype.hasOwnProperty.call(err, 'message')) {
        message = (err as Error).message;
    } else if (typeof err === 'object') {
        message = JSON.stringify(err);
    } else {
        message = `${err}`;
    }

    return { message, stack };
}
