import { createAction, props } from '@ngrx/store';

import { Session } from 'app/auth/store/session/session.state';

export enum SessionActionTypes {
    SetSession = '[Login Session] Set Login Session',
    SessionSet = '[Login Session] Login Session Set',
    ClearSession = '[Login Session] Clear Login Session',
    CheckSessionAfterTime = '[Login Session] Check Login Session After Time',
}

export const sessionSet = createAction(SessionActionTypes.SessionSet, props<{ session: Session }>());
export const setSession = createAction(SessionActionTypes.SetSession, props<{ session: Session }>());
export const clearSession = createAction(SessionActionTypes.ClearSession);
export const checkSessionAfterTime =
    createAction(SessionActionTypes.CheckSessionAfterTime, props<{ milliseconds: number }>());

export default {
    setSession,
    sessionSet,
    clearSession,
    checkSessionAfterTime,
};
