import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

export interface Storage {
    [name: string]: string;
}

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    private readonly localStorageSubject: BehaviorSubject<Readonly<Storage>>;

    readonly localStorage: Observable<Readonly<Storage>>;

    constructor() {
        this.localStorageSubject = new BehaviorSubject<Storage>(getStorageAsHash());
        this.localStorage = this.localStorageSubject.asObservable();

        // Note: this event is only called when the storage is modified in a different tab, so this will not pick up any modifications
        // that were made in this app
        window.addEventListener('storage', () => {
            this.update();
        });
    }

    clear(): void {
        localStorage.clear();
        this.update();
    }

    getItem(key: string): string | null {
        this.update();
        // this defaults to null because that's what localStorage.getItem() does
        return this.localStorageSubject.value[key] ?? null;
    }

    removeItem(key: string): void {
        localStorage.removeItem(key);
        this.update();
    }

    setItem(key: string, value: string): void {
        localStorage.setItem(key, value);
        this.update();
    }

    watchItem(key: string): Observable<string | null> {
        this.update();  // pick up any updates made directly to local storage
        return this.localStorage.pipe(
            map((storage) => storage[key] ?? null),
            distinctUntilChanged(),
        );
    }

    private update(): void {
        this.localStorageSubject.next(getStorageAsHash());
    }
}

function getStorageAsHash(): Readonly<Storage> {
    const storage: Storage = {};

    for (const [key, value] of Object.entries(localStorage)) {
        storage[key] = value;
    }

    return Object.freeze(storage);
}
