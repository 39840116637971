import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminComponent } from 'app/admin/admin.component';
import { IpWhitelistingManagementComponent } from 'app/admin/containers/ip-whitelisting-management/ip-whitelisting-management.component';
import { IpWhitelistingGuard } from 'app/admin/guards/ip-whitelisting.guard';

const routes: Routes = [
    { path: 'admin', component: AdminComponent, children: [
        { path: '', pathMatch: 'full', redirectTo: 'ip-whitelisting' },
        { path: 'ip-whitelisting', pathMatch: 'full', component: IpWhitelistingManagementComponent, canActivate: [IpWhitelistingGuard] },
    ] },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AdminRoutingModule {
}
