<div class="password-change-page">
        <cc-text-input #oldPassword
            id="oldPassword"
            [label]="'Old Password'"
            [inputType]="'password'"
            [size]="'fit-to-parent'"
            [error]="oldPasswordError"
            (outOfFocus)="checkOldPassword()"
            (valueChange)="onOldPasswordChange($event)"
        />
        <cc-text-input #newPassword
            id="newPassword"
            [label]="'New Password'"
            [size]="'fit-to-parent'"
            [inputType]="'password'"
            (inFocus)="passwordsComplexityCheck()"
            (outOfFocus)="passwordsComplexityCheck()"
            [error]="newPasswordError"
            (valueChange)="onNewPasswordChange($event)"
        />
        <cc-text-input #confirmNewPassword
            id="newPasswordConfirm"
            [label]="'New Password (confirm)'"
            [inputType]="'password'"
            [size]="'fit-to-parent'"
            [error]="confirmPasswordError"
            (valueChange)="onConfirmPasswordChange($event)"
        />

        <div class="actions" (mouseenter)="newPasswordsMatch()">
            <cc-button
                [type]="'outline'"
                [disabled]="validatorsFail() || (requestInFlight$ | async)"
                (pressed)="onPasswordChangeSubmit()"
            >Change
            </cc-button>
        </div>
        <app-password-guidelines></app-password-guidelines>
</div>
