import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { forkJoin, Observable, of, pipe } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import {
    selectSelectedClient,
} from 'app/admin/store/ip-whitelisting/ip-whitelisting.selectors';
import { preventErrorsFromCompletingStream } from 'app/auth/utils/stream';
import { AuthService } from 'app/core/services/auth/auth.service';
import { IpWhitelistingService } from 'app/core/services/ip-whitelisting/ip-whitelisting.service';

import {
    addWhitelistedIpForClient,
    clientListFetched,
    clientSelected,
    fetchClientList,
    fetchUserPermissions,
    networkCallFailed,
    removeWhitelistedIpForClient,
    selectClientToManage,
    setRequestInFlight,
    setWhitelistingEnabledForClient,
    userPermissionsFetched,
    updateWhitelistedIpForClient,
    whitelistedIpForClientUpdated,
    whitelistedIpsForClientUpdated,
} from './ip-whitelisting.actions';

@Injectable()
export class IpWhitelistingEffects {
    fetchClientList$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(fetchClientList),
            preventErrorsFromCompletingStream(pipe(
                tap(() => this.store.dispatch(setRequestInFlight({ requestInFlight: true }))),
                switchMap(() => this.ipWhitelistingService.getClientList()),
                map((clientList) => clientListFetched({ clientList })),
                catchError((error: HttpErrorResponse) => of(networkCallFailed({ error }))),
                tap(() => this.store.dispatch(setRequestInFlight({ requestInFlight: false }))),
            )),
        );
    });

    selectClient$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(selectClientToManage),
            preventErrorsFromCompletingStream(pipe(
                tap(() => this.store.dispatch(setRequestInFlight({ requestInFlight: true }))),
                switchMap((action) => forkJoin({
                    clientCode: of(action.clientCode),
                    whitelistingEntry: this.ipWhitelistingService.getWhitelistedIpsForClient(action.clientCode),
                    operationLog: this.ipWhitelistingService.getWhitelistOperationLogForClient(action.clientCode),
                })),
                map(({ clientCode, whitelistingEntry, operationLog }) => clientSelected({ clientCode, whitelistingEntry, operationLog })),
                catchError((error: HttpErrorResponse) => of(networkCallFailed({ error }))),
                tap(() => this.store.dispatch(setRequestInFlight({ requestInFlight: false }))),
            )),
        );
    });

    addWhitelistedIpForClient$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(addWhitelistedIpForClient),
            preventErrorsFromCompletingStream(pipe(
                tap(() => this.store.dispatch(setRequestInFlight({ requestInFlight: true }))),
                switchMap((action) => this.ipWhitelistingService.addOrUpdateWhitelistedIpForClient(action.clientCode, action.whitelistEntry)
                    .pipe(switchMap((updatedEntry) => this.ipWhitelistingService.getWhitelistOperationLogForClient(action.clientCode)
                        .pipe(map((updatedLog) => ({ updatedEntry, updatedLog }),
                        ))),
                    )),
                map(({ updatedEntry, updatedLog }) => whitelistedIpsForClientUpdated({ updatedEntry, updatedLog })),
                catchError((error: HttpErrorResponse) => of(networkCallFailed({ error }))),
                tap(() => this.store.dispatch(setRequestInFlight({ requestInFlight: false }))),
            )),
        );
    });

    removeWhitelistedIpForClient$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(removeWhitelistedIpForClient),
            preventErrorsFromCompletingStream(pipe(
                tap(() => this.store.dispatch(setRequestInFlight({ requestInFlight: true }))),
                switchMap((action) => this.ipWhitelistingService.removeWhitelistedIpForClient(action.clientCode, action.whitelistEntry)
                    .pipe(
                        switchMap((updatedEntry) => this.ipWhitelistingService.getWhitelistOperationLogForClient(action.clientCode)
                            .pipe(map((updatedLog) => ({ updatedEntry, updatedLog }),
                            ))),
                    )),
                map(({ updatedEntry, updatedLog }) => {
                    const noIpsLeft = updatedEntry.ips.length === 0;
                    const clientStillEnabled = updatedEntry.enabled;
                    if (noIpsLeft && clientStillEnabled) {
                        const clientCode = updatedLog[0].client;
                        alert(`Whitelisting with no addresses will prevent all ${clientCode} users from logging in so it has been turned off.`);
                        return setWhitelistingEnabledForClient({ clientCode, enabled: false });
                    } else {
                        return whitelistedIpsForClientUpdated({ updatedEntry, updatedLog });
                    }
                }),
                catchError((error: HttpErrorResponse) => of(networkCallFailed({ error }))),
                tap(() => this.store.dispatch(setRequestInFlight({ requestInFlight: false }))),
            )),
        );
    });

    updateWhitelistedIpForClient$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateWhitelistedIpForClient),
            preventErrorsFromCompletingStream(pipe(
                tap(() => this.store.dispatch(setRequestInFlight({ requestInFlight: true }))),
                switchMap((action) => this.ipWhitelistingService.addOrUpdateWhitelistedIpForClient(action.clientCode, action.whitelistEntry)
                    .pipe(
                        switchMap((updatedEntry) => this.ipWhitelistingService.getWhitelistOperationLogForClient(action.clientCode)
                            .pipe(map((updatedLog) => ({ updatedEntry, updatedLog }),
                            ))),
                    )),
                map(({ updatedEntry, updatedLog }) => whitelistedIpForClientUpdated({ updatedEntry, updatedLog })),
                catchError((error: HttpErrorResponse) => of(networkCallFailed({ error }))),
                tap(() => this.store.dispatch(setRequestInFlight({ requestInFlight: false }))),
            )),
        );
    });

    setWhitelistingEnabledForClient$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(setWhitelistingEnabledForClient),
            preventErrorsFromCompletingStream(pipe(
                withLatestFrom(this.store.select(selectSelectedClient)),
                filter(([action, selectedClient]) => {
                    if (action.enabled && !selectedClient?.whitelistingEntry.ips.length) {
                        alert(
                            `Enabling whitelisting with no addresses will prevent all ${action.clientCode} users from logging in. ` +
                        'Add a valid address before enabling.',
                        );
                        return false;
                    } else {
                        return true;
                    }
                }),
                map(([action]) => action),
                tap(() => this.store.dispatch(setRequestInFlight({ requestInFlight: true }))),
                switchMap((action) => this.ipWhitelistingService.setWhitelistingEnabledForClient(action.clientCode, action.enabled).pipe(
                    switchMap((updatedEntry) => this.ipWhitelistingService.getWhitelistOperationLogForClient(action.clientCode).pipe(map(
                        (updatedLog) => ({ updatedEntry, updatedLog }),
                    ))),
                )),
                map(({ updatedEntry, updatedLog }) => whitelistedIpsForClientUpdated({ updatedEntry, updatedLog })),
                catchError((error: HttpErrorResponse) => of(networkCallFailed({ error }))),
                tap(() => this.store.dispatch(setRequestInFlight({ requestInFlight: false }))),
            )),
        );
    });

    fetchUserPermissions$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(fetchUserPermissions),
            preventErrorsFromCompletingStream(pipe(
                tap(() => this.store.dispatch(setRequestInFlight({ requestInFlight: true }))),
                switchMap(() => this.authService.getUser()),
                map(({ canManageIpWhitelisting }) => userPermissionsFetched({
                    canManageIpWhitelisting,
                })),
                catchError((error: HttpErrorResponse) => of(networkCallFailed({ error }))),
                tap(() => this.store.dispatch(setRequestInFlight({ requestInFlight: false }))),
            )),
        );
    });

    networkCallFailed$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(networkCallFailed),
            tap((action) => alert(action.error?.error?.message ?? JSON.stringify(action.error))),
        );
    }, { dispatch: false });

    constructor(
        private readonly actions$: Actions,
        private readonly ipWhitelistingService: IpWhitelistingService,
        private readonly authService: AuthService,
        private readonly store: Store,
    ) {
    }
}
