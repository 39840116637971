import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OptionToggleComponent } from '@hs/ui-core-inputs';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import {
    addWhitelistedIpForClient,
    fetchClientList,
    fetchUserPermissions,
    selectClientToManage,
    setWhitelistingEnabledForClient,
} from 'app/admin/store/ip-whitelisting/ip-whitelisting.actions';
import {
    selectSelectedClient,
    selectClientList,
    selectRequestInFlight,
    selectIsMaintainer,
} from 'app/admin/store/ip-whitelisting/ip-whitelisting.selectors';
import { SelectedClientState } from 'app/admin/store/ip-whitelisting/ip-whitelisting.state';
import { AuthState } from 'app/auth/store';
import { IpWhitelistingService } from 'app/core/services/ip-whitelisting/ip-whitelisting.service';

@Component({
    selector: 'app-ip-whitelisting-management',
    templateUrl: './ip-whitelisting-management.component.html',
    styleUrls: ['./ip-whitelisting-management.component.scss'],
})
export class IpWhitelistingManagementComponent implements OnInit {
    @ViewChild(NgSelectComponent) clientSelector: NgSelectComponent;
    entryForm: FormGroup;

    clientList$: Observable<string[] | undefined>;
    selectedClient$: Observable<SelectedClientState | undefined>;
    requestInFlight$: Observable<boolean>;
    canBeManaged$: Observable<boolean>;

    isUpdateMode: boolean = true;
    readonly options: string[] = [
        'Personal',
        'Corporate',
        'Third Party',
    ];

    constructor(
        private readonly store: Store<AuthState>,
        private readonly service: IpWhitelistingService,
    ) {
        this.clientList$ = this.store.select(selectClientList);
        this.selectedClient$ = this.store.select(selectSelectedClient);
        this.requestInFlight$ = this.store.select(selectRequestInFlight);
        this.canBeManaged$ = this.store.select(selectIsMaintainer);
    }

    ngOnInit(): void {
        this.store.dispatch(fetchClientList());
        this.store.dispatch(fetchUserPermissions());
        this.createForm();
    }

    downloadCsv(): void {
        this.service.getWhitelistedIpsForAllClients().subscribe(downloadCsv);
    }

    get areChangesPending(): boolean {
        return this.entryForm.pristine || this.entryForm.invalid;
    }

    public onClientSelected(selectedClient: string): void {
        this.setDefaultValues();
        this.store.dispatch(selectClientToManage({ clientCode: selectedClient }));
        this.clientSelector.blur();
    }

    public onOptionToggled(
        selectedClient: SelectedClientState | undefined,
        optionToggle: OptionToggleComponent,
        enabled: boolean,
    ): void {
        if (selectedClient) {
            this.store.dispatch(setWhitelistingEnabledForClient({
                clientCode: selectedClient?.clientCode,
                enabled,
            }));

            if (!selectedClient?.whitelistingEntry.ips.length) {
                optionToggle.value = false;
            }
        }
    }

    public onWhitelistEntryAdded(clientCode: string): void {
        this.store.dispatch(addWhitelistedIpForClient({ clientCode, whitelistEntry: this.entryForm.value }));
        this.clearIpInputOnRequestComplete();
    }

    public searchClientList(term: string, item: string): boolean {
        return item.toUpperCase().includes(term.toUpperCase());
    }

    private clearIpInputOnRequestComplete(): void {
        this.requestInFlight$.pipe(
            filter((requestnFlight) => !requestnFlight),
            take(1),
        ).subscribe(() => this.setDefaultValues());
    }

    private setDefaultValues(): void {
        this.entryForm.reset();
    }

    private createForm(): void {
        this.entryForm = new FormGroup({
            ipAddress: new FormControl('', Validators.required),
            type: new FormControl('', Validators.required),
            comment: new FormControl('', { nonNullable: true }),
        });
    }
}

export function downloadCsv(response: Blob): void {
    const filename = createFilename('allowed-ips', 'csv');
    const url = window.URL.createObjectURL(response);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}

export function createFilename(filename: string, extension: string): string {
    const dateStr = new Date().toISOString().split('T')[0];
    return `${dateStr}-${filename}.${extension}`;
}
