import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of, OperatorFunction, pipe } from 'rxjs';
import { catchError, map, skip, switchMap, takeUntil, tap } from 'rxjs/operators';

import { DUO_ACCOUNT_LOCKED, YOUR_PASSWORD_HAS_EXPIRED } from 'app/auth/errors/messages';
import { AuthState } from 'app/auth/store';
import {
    checkTwoFactorNotificationStatus,
    clearTrustedDeviceToken,
    fetchDevices,
    fetchTwoFactorUserStatus,
    logout,
    sendTwoFactorNotification,
    setRequestInFlight,
    setTrustedDeviceToken,
    setTwoFactorUserStatus,
    verifyPasscode,
} from 'app/auth/store/auth/auth.actions';
import { selectRequestInFlight } from 'app/auth/store/auth/auth.selectors';
import { DeviceCapability } from 'app/auth/store/auth/auth.state';
import { setAuthMethod } from 'app/auth/store/auth-method';
import { AuthMethod } from 'app/auth/store/auth-method/auth-method.state';
import { clearFailure, setFailure } from 'app/auth/store/failure';
import { clearMessage, setMessage } from 'app/auth/store/message';
import { redirect } from 'app/auth/store/redirect';
import { preventErrorsFromCompletingStream } from 'app/auth/utils/stream';
import { AuthService } from 'app/core/services/auth/auth.service';
import { CheckNotificationStatusResponseDTO } from 'app/core/services/auth/dto';
import { LocalStorageService } from 'app/shared/services/local-storage/local-storage.service';
import { Logger } from 'app/shared/services/logging/logger';
import { NamedLogger } from 'app/shared/utils/logging/named-logger';

@Injectable()
export class TwoFactorEffects extends NamedLogger {
    sendTwoFactorNotification$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(sendTwoFactorNotification),
            preventErrorsFromCompletingStream(pipe(
                tap(() => {
                    this.store.dispatch(setRequestInFlight({ requestInFlight: true }));
                    this.store.dispatch(clearMessage());
                }),
                switchMap((notification) => {
                    const { authMethod } = notification;
                    if (factorRequiresSendingANotification(authMethod.factor)) {
                        this.logDebug(`Sending ${authMethod.factor} notification to ${authMethod.device.id}`);
                        return this.authService.sendTwoFactorNotification(authMethod.factor, authMethod.device.id)
                            .pipe(map(() => notification));
                    } else {
                        return of(notification);
                    }
                }),
                tap((notification) => this.navigateTo(this.determineNavigationRouteFor(notification.authMethod))),
                switchMap((authMethod) => {
                    return [
                        setRequestInFlight({ requestInFlight: false }),
                        setAuthMethod(authMethod),
                    ];
                }),
                catchError((err) => {
                    this.navigateTo('/authentication-selection');
                    this.logError('Error sending two-factor notification', err);
                    return [
                        setRequestInFlight({ requestInFlight: false }),
                        setMessage({ message: err.error?.message }),
                    ];
                }))),
        );
    });

    checkTwoFactorNotificationStatus$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(checkTwoFactorNotificationStatus),
            preventErrorsFromCompletingStream(pipe(
                tap(() => this.store.dispatch(setRequestInFlight({ requestInFlight: true }))),
                switchMap(() => this.authService.checkTwoFactorNotificationStatus()
                    .pipe(
                        this.retryUntilNotificationFinishes,
                        // Cancel effect if someone else sets requestInFlight
                        // 1 for the initial subscribe + 1 for the dispatch above = 2;
                        takeUntil(this.store.select(selectRequestInFlight).pipe(skip(2))),
                    ),
                ),
                switchMap((result) => {
                    switch (result.status) {
                        case 'allow': {
                            const actions: Action[] = [];
                            if (result.trustedDeviceToken) {
                                actions.push(setTrustedDeviceToken({ token: result.trustedDeviceToken }));
                            } else {
                                actions.push(clearTrustedDeviceToken());
                            }

                            if (result.passwordChangeRequired) {
                                this.logInfo('Password change required');
                                this.navigateTo('/change-password');
                                actions.push(
                                    setMessage({ message: 'Your password has expired, Please change it before logging in' }),
                                    clearFailure(),
                                );
                            } else {
                                this.logInfo('Successfully completed two-factor authentication');
                                actions.push(redirect());
                            }

                            return actions;
                        }
                        case 'deny':
                            this.logWarning('Two-factor authentication failed');
                            this.navigateTo('/authentication-selection');
                            return [
                                setMessage({ message: 'Confirmation Failed' }),
                                setFailure(),
                            ];
                        default:
                            this.logError('Invalid notification status:', result.status);
                            throw new Error(`Unhandled notification status: ${result.status}`);
                    }
                }),
                catchError((e) => {
                    this.logError('Error occurred during two-factor notification status check:', e);
                    this.navigateTo('/authentication-selection');
                    return [setMessage({ message: e.error?.message })];
                }),
            )),
        );
    });

    fetchDevices$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(fetchDevices),
            map(() => fetchTwoFactorUserStatus()),
        );
    });

    fetchTwoFactorUserStatus$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(fetchTwoFactorUserStatus),
            preventErrorsFromCompletingStream(pipe(
                switchMap(() => this.authService.getTwoFactorUserStatus()),
                switchMap((twoFactorUserStatus) => {
                    if (twoFactorUserStatus.isBlocked) {
                        this.logError('User is blocked - Duo account is locked');
                        return [
                            logout(),
                            setMessage({ message: DUO_ACCOUNT_LOCKED }),
                        ];
                    } else {
                        this.logDebug('Two-factor user status:', twoFactorUserStatus);
                        return [setTwoFactorUserStatus({ twoFactorUserStatus })];
                    }
                }),
                catchError((e) => {
                    this.logError('Error occurred during two-factor user status check:', e);
                    this.navigateTo('/authentication-selection');
                    return [setMessage({ message: e.error?.message ?? '' })];
                }),
            )),
        );
    });

    verifyPasscode$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(verifyPasscode),
            preventErrorsFromCompletingStream(pipe(
                tap(() => this.store.dispatch(setRequestInFlight({ requestInFlight: true }))),
                switchMap(({ passcode }) => this.authService.verifyPasscode(passcode)),
                switchMap((result) => {
                    switch (result.status) {
                        case 'allow': {
                            const actions: Action[] = [];
                            if (result.trustedDeviceToken) {
                                actions.push(setTrustedDeviceToken({ token: result.trustedDeviceToken }));
                            } else {
                                actions.push(clearTrustedDeviceToken());
                            }

                            if (result.passwordChangeRequired) {
                                this.logInfo('Passcode accepted, password change required');
                                this.navigateTo('/change-password');
                                actions.push(setMessage({ message: YOUR_PASSWORD_HAS_EXPIRED }), clearFailure());
                            } else {
                                this.logInfo('Passcode accepted');
                                actions.push(clearMessage(), clearFailure(), redirect());
                            }

                            return actions;
                        }
                        case 'deny':
                            this.logInfo('Passcode rejected');
                            this.navigateTo('/authentication-selection');
                            return [setMessage({ message: 'Confirmation Failed' }), setFailure()];
                        default:
                            this.logError('Invalid notification status:', result.status);
                            throw new Error(`Unhandled notification status: ${result.status}`);
                    }
                }),
                catchError((e) => {
                    this.logError('Error occurred during two-factor passcode check:', e);
                    this.navigateTo('/authentication-selection');
                    return [setMessage({ message: e.error?.message ?? '' })];
                }),
            )),
        );
    });

    private readonly retryUntilNotificationFinishes:
    OperatorFunction<CheckNotificationStatusResponseDTO, CheckNotificationStatusResponseDTO> = pipe(
            switchMap(
                (response: CheckNotificationStatusResponseDTO): Observable<CheckNotificationStatusResponseDTO> => {
                    this.logInfo('two-factor notification status:', response.status);
                    return response.status !== 'waiting' ?
                        of(response) :
                        this.authService.checkTwoFactorNotificationStatus().pipe(this.retryUntilNotificationFinishes);
                },
            ),
        );

    constructor(
        private readonly actions$: Actions,
        private readonly authService: AuthService,
        private readonly localStorageService: LocalStorageService,
        private readonly router: Router,
        private readonly store: Store<AuthState>,
        logger: Logger,
    ) {
        super(logger);
    }

    private determineNavigationRouteFor(authMethod: AuthMethod): string {
        this.logDebug('Authentication method', authMethod);
        switch (authMethod.factor) {
            case 'call':
            case 'push':
                return '/push';
            case 'otp':
            case 'sms':
            case 'token_otp':
                return '/passcode';
            default:
                this.logError(`Invalid authentication method ${authMethod.factor}`);
                throw new Error(`Invalid or not implemented authentication method: ${authMethod.factor}`);
        }
    }

    private navigateTo(path: string): void {
        this.router.navigate([path]).then(
            (result) => {
                this.logDebug(`Navigation to ${path} succeeded?`, result);
            },
            (error) => {
                this.logError(`Navigation to ${path} failed:`, error);
            },
        );
    }
}

function factorRequiresSendingANotification(factor: DeviceCapability): boolean {
    return (
        factor === 'push' ||
        factor === 'sms' ||
        factor === 'call' ||
        factor === 'auto'
    );
}
