import { of, OperatorFunction } from 'rxjs';
import { reduce, switchMap } from 'rxjs/operators';

export function aggregateAllEmits<T>(): OperatorFunction<T, T[]> {
    return reduce((emits: T[], current) => {
        emits.push(current);
        return emits;
    }, []);
}

export function preventErrorsFromCompletingStream<T1, T2>(fn: OperatorFunction<T1, T2>): OperatorFunction<T1, T2> {
    return switchMap((data) => of(data).pipe(fn));
}
