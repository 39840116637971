import { createAction, props } from '@ngrx/store';

export enum MessageActionTypes {
    SetMessage = '[Message] Set Message',
    ClearMessage = '[Message] Clear Message',
}

export const setMessage = createAction(MessageActionTypes.SetMessage, props<{ message: string }>());

export const clearMessage = createAction(MessageActionTypes.ClearMessage);
