import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { InputComponent } from './components/input/input.component';
import { ErrorHandlerService } from './services/logging/error-handler.service';
import { Logger } from './services/logging/logger';
import { LoggerService } from './services/logging/logger.service';

@NgModule({
    declarations: [
        InputComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
    ],
    providers: [
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        { provide: Logger, useClass: LoggerService },
    ],
    exports: [
        InputComponent,
    ],
})
export class SharedModule {}
