import { Action, createReducer, on } from '@ngrx/store';

import {
    clientListFetched,
    clientSelected,
    setRequestInFlight,
    userPermissionsFetched,
    whitelistedIpForClientUpdated,
    whitelistedIpsForClientUpdated,
} from './ip-whitelisting.actions';
import { IpWhitelistingState } from './ip-whitelisting.state';

export const ipWhitelistingKey = 'ipWhitelisting';

const initialState: IpWhitelistingState = { requestInFlight: false, isMaintainer: false };

const reducer = createReducer(
    initialState,

    on(setRequestInFlight, (state, { requestInFlight }): IpWhitelistingState => ({ ...state, requestInFlight })),

    on(clientListFetched, (state, { clientList }): IpWhitelistingState => ({ ...state, clientList })),

    on(clientSelected, (state, { clientCode, whitelistingEntry, operationLog }): IpWhitelistingState => ({
        ...state,
        selectedClient: { clientCode, whitelistingEntry, operationLog },
    })),

    on(whitelistedIpsForClientUpdated, (state, { updatedEntry, updatedLog }): IpWhitelistingState => {
        if (state.selectedClient) {
            return ({
                ...state,
                selectedClient: {
                    ...state.selectedClient,
                    whitelistingEntry: updatedEntry,
                    operationLog: updatedLog,
                },
            });
        } else {
            return state;
        }
    }),

    on(userPermissionsFetched, (state, { canManageIpWhitelisting }): IpWhitelistingState =>
        ({ ...state, isMaintainer: canManageIpWhitelisting })),

    on(whitelistedIpForClientUpdated, (state, { updatedEntry, updatedLog }): IpWhitelistingState => {
        if (state.selectedClient) {
            return ({
                ...state,
                selectedClient: {
                    ...state.selectedClient,
                    whitelistingEntry: updatedEntry,
                    operationLog: updatedLog,
                },
            });
        } else {
            return state;
        }
    }),
);

export function ipWhitelistingReducer(state: IpWhitelistingState | undefined, action: Action): IpWhitelistingState {
    return reducer(state, action);
}
