import { createAction, props } from '@ngrx/store';

import { AuthMethod } from './auth-method.state';

export enum AuthMethodActionTypes {
    SetAuthMethod = '[AuthMethod] Set AuthMethod',
    ClearAuthMethod = '[AuthMethod] Clear AuthMethod',
}

export const setAuthMethod = createAction(AuthMethodActionTypes.SetAuthMethod, props<{ authMethod: AuthMethod }>());

export const clearAuthMethod = createAction(AuthMethodActionTypes.ClearAuthMethod);
