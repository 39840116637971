<div class="authentication-selection-page">
    <form (ngSubmit)="sendConfirmation()">
        <div>
            <label for="authentication-method">{{ (hasFailed$ | async) ? 'Retry Confirmation' : 'Confirm' }} Via</label>
            <ng-select
                id="authentication-method"
                name="authentication-method"
                [(ngModel)]="selectedAuthenticationMethod"
                [searchable]="false"
                [clearable]="false"
                [items]="options"
                placeholder="Choose authentication method"
                bindLabel="text"
            ></ng-select>
        </div>
        <div class="actions">
            <cc-button
                [type]="'outline'"
                [disabled]="!selectedAuthenticationMethod || (requestInFlight$ | async)"
                [behavior]="'submit'"
            >{{ (hasFailed$ | async) ? 'Retry' : 'Confirm' }}
            </cc-button>
        </div>
    </form>
    <app-need-help></app-need-help>
</div>
