import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './maintenance.actions';
import { MaintenanceState } from './maintenance.state';

export const maintenanceKey = 'maintenance';

export const initialState: MaintenanceState = {
    maintenanceWindow: {
        start: null,
        end: null,
        isActive: false,
    },
};

const reducer = createReducer(
    initialState,
    on(fromActions.fetchMaintenanceWindowSuccess, (state, { maintenanceWindow }) => {
        return {
            ...state,
            maintenanceWindow,
        };
    }),
);

export function maintenanceReducer(state: MaintenanceState | undefined, action: Action): MaintenanceState {
    return reducer(state, action);
}
