import { TwoFactorUserStatus } from 'app/auth/store/auth/auth.state';

export interface SendTwoFactorNotificationResponseDTO {
    result: 'SUCCESS' | 'DEVICE_NOT_CAPABLE';
}

export interface LoginAuthenticateResponseDTO {
    status: LoginAuthenticateStatus;
}

export interface LoginAuthenticateResponseRequiresTwoFactorDTO extends LoginAuthenticateResponseDTO {
    status: LoginAuthenticateStatus.RequiresTwoFactorAuthentication;
    twoFactorUserStatus: TwoFactorUserStatus;
}

export enum LoginAuthenticateStatus {
    AuthenticatedWithoutTwoFactor = 'AUTHENTICATED_WITHOUT_TWO_FACTOR',
    RequiresTwoFactorAuthentication = 'REQUIRES_TWO_FACTOR_AUTHENTICATION',
    RequiresPasswordChange = 'REQUIRES_PASSWORD_CHANGE',
}

export interface PasswordChangeRequestDTO {
    oldPassword: string;
    newPassword: string;
}

export interface PasswordChangeResponseDTO {
    success: true;
}

interface SessionBase {
    isLoggedIn: boolean;
    credentialsAuthenticated: boolean;
    twoFactorAuthenticationRequired: boolean;
    passwordChangeRequired: boolean;
    timeLeftMs: number;
}

export interface SessionDTO extends SessionBase {
    expires: string;
    currentTime: string;
}

export interface Session extends SessionBase {
    expires: Date;
    currentTime: Date;
}

export interface CheckNotificationStatusResponseDTO {
    status: 'allow' | 'deny' | 'waiting';
    passwordChangeRequired: boolean;
    trustedDeviceToken?: string;
}

export interface UserDTO {
    userName: string;
    fullName: string;
    email: string | null;
    canManageIpWhitelisting: boolean;
    canViewIpWhitelisting: boolean;
    canEmulate: boolean;
    isEmulating: boolean;
    emulator: string | null;
    internal: boolean;
}
