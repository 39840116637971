import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { MaintenanceWindow } from './maintenance.state';

export enum MaintenanceActionTypes {
    FetchMaintenanceWindowRequest = '[Maintenance] Fetch Maintenance Maintenance Request',
    FetchMaintenanceWindowSuccess = '[Maintenance] Fetch Maintenance Maintenance Success',
    FetchMaintenanceWindowFailure = '[Maintenance] Fetch Maintenance Maintenance Failure',
}

export const fetchMaintenanceWindowRequest = createAction(MaintenanceActionTypes.FetchMaintenanceWindowRequest);
export const fetchMaintenanceWindowSuccess = createAction(MaintenanceActionTypes.FetchMaintenanceWindowSuccess,
    props<{ maintenanceWindow: MaintenanceWindow }>());
export const fetchMaintenanceWindowFailure = createAction(MaintenanceActionTypes.FetchMaintenanceWindowFailure,
    props<{ error: HttpErrorResponse }>());
