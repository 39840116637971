import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { interval, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { checkTwoFactorNotificationStatus } from 'app/auth/store/auth/auth.actions';
import { setFailure } from 'app/auth/store/failure';
import { setMessage } from 'app/auth/store/message';
import { Logger } from 'app/shared/services/logging/logger';
import { NamedLogger } from 'app/shared/utils/logging/named-logger';

@Component({
    selector: 'app-push',
    templateUrl: './push.component.html',
    styleUrls: ['./push.component.scss'],
})
export class PushComponent extends NamedLogger implements OnInit, OnDestroy {
    tickerSubscription: Subscription;
    timeSpan: number = 59;
    timer: number = 59;
    showAuthSelectionLink: boolean = false;

    constructor(
        private readonly store: Store,
        private readonly router: Router,
        logger: Logger,
    ) {
        super(logger);
        this.redirectOnRefresh();
    }

    ngOnInit(): void {
        this.tickerSubscription = interval(1000)
            .pipe(take(this.timeSpan))
            .subscribe({
                next: (time: number) => {
                    this.timer = this.timeSpan - time - 1;

                    if (this.timer < 30) {
                        this.showAuthSelectionLink = true;
                    }
                },
                complete: async () => {
                    this.store.dispatch(setMessage({ message: 'Confirmation Failed: Timed Out' }));
                    this.store.dispatch(setFailure());
                    await this.router.navigate(['/authentication-selection']);
                },
            });

        this.store.dispatch(checkTwoFactorNotificationStatus());
    }

    ngOnDestroy(): void {
        this.tickerSubscription?.unsubscribe();
    }

    async selectAlternativeMethod(): Promise<void> {
        await this.router.navigate(['/authentication-selection']);
    }

    private redirectOnRefresh(): void {
        // this is a bit hacky and it's not really documented, but a NavigationEnd event with an id of 1 means that the page was just loaded
        // if a user refreshes the page, we want to just take them to the factor selection page
        this.router.events
            .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd && event.id === 1))
            .subscribe(() => {
                this.router.navigate(['/authentication-selection'])
                    .catch((e) => this.logError(`Failed to navigate to authentication selection page: ${e.stack}`));
            });
    }
}
