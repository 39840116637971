<cc-expansion-panel>
    <span ep-header>
        Password Guidelines / Help
    </span>
    <div ep-content class="guidelines-container guidelines-content">
        <div class="section">
            <div class="header">General Guidelines</div>
            <div class="blurbs">
                <div class="line">You cannot use any of your previous 24 passwords</div>
                <div class="line">You can only change your password once a day</div>
                <div class="line">Your account will lock after 5 failed attempts</div>
            </div>
        </div>
        <div class="section">
            <div class="header">Complexity Requirements</div>
            <div class="line">Your password must be at least 16 characters</div>
            <div class="line">Your password must not contain any part of your username</div>
            <div class="line">The password must contain 3 of the following:</div>
            <ul>
                <li class="requirement">An uppercase letter</li>
                <li class="requirement">A lowercase letter</li>
                <li class="requirement">A number</li>
                <li class="requirement">A special character: !, &#64;, etc.</li>
            </ul>
        </div>
        <div class="section">If you are still unable to login please contact your client user team.</div>
    </div>
</cc-expansion-panel>
