import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { defer, Observable, of, pipe } from 'rxjs';
import { catchError, filter, map, mapTo, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { YOUR_PASSWORD_HAS_EXPIRED } from 'app/auth/errors/messages';
import { AuthState } from 'app/auth/store';
import {
    changePassword,
    resetLoginState,
    clearTrustedDeviceToken,
    loginAuthenticate,
    loginSetup,
    logout,
    sendTwoFactorNotification,
    setCredentialsToken,
    setRequestInFlight,
    setTrustedDeviceToken,
    setTwoFactorUserStatus,
} from 'app/auth/store/auth/auth.actions';
import { selectCredentialsToken, selectUsername } from 'app/auth/store/auth/auth.selectors';
import { Device } from 'app/auth/store/auth/auth.state';
import { AuthMethod } from 'app/auth/store/auth-method/auth-method.state';
import { clearMessage, setMessage } from 'app/auth/store/message';
import { redirect } from 'app/auth/store/redirect';
import { clearSession, setSession } from 'app/auth/store/session/session.actions';
import { preventErrorsFromCompletingStream } from 'app/auth/utils/stream';
import { AuthService } from 'app/core/services/auth/auth.service';
import {
    LoginAuthenticateResponseDTO,
    LoginAuthenticateResponseRequiresTwoFactorDTO,
    LoginAuthenticateStatus,
    Session,
} from 'app/core/services/auth/dto';
import { Logger } from 'app/shared/services/logging/logger';
import { NamedLogger } from 'app/shared/utils/logging/named-logger';

@Injectable()
export class AuthEffects extends NamedLogger {
    init$: Observable<Action> = createEffect(() => {
        return defer(() =>
            this.router.events.pipe(
                filter((event) => event instanceof NavigationEnd),
                mapTo(setRequestInFlight({ requestInFlight: false })),
            ),
        );
    });

    loginSetup$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(loginSetup),
            preventErrorsFromCompletingStream(pipe(
                tap(() => this.store.dispatch(clearMessage())),
                tap(() => this.store.dispatch(setRequestInFlight({ requestInFlight: true }))),
                switchMap(({ username }: { username: string }) => this.authService.loginSetup(username)),
                tap(() => this.store.dispatch(setRequestInFlight({ requestInFlight: false }))),
                map((response) => {
                    return setCredentialsToken({ credentialsToken: response.token, trustedDeviceEnabled: response.trustedDeviceEnabled });
                }),
                catchError((response: HttpErrorResponse) => {
                    this.logError('Error setting up login:', response.error);
                    this.store.dispatch(setRequestInFlight({ requestInFlight: false }));
                    return of(setMessage({ message: response.error?.message }));
                }))),
        );
    });

    loginAuthenticate$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(loginAuthenticate),
            preventErrorsFromCompletingStream(pipe(
                withLatestFrom(this.store.select(selectCredentialsToken), this.store.select(selectUsername)),
                tap(() => this.store.dispatch(setRequestInFlight({ requestInFlight: true }))),
                switchMap(([{ password, rememberDevice }, credentialsToken, username]) => {
                    if (!username) {
                        throw new Error('username is null');
                    }

                    if (!credentialsToken) {
                        throw new Error('credentialsToken is null');
                    }

                    return this.authService.authenticate(username, credentialsToken, password, rememberDevice);
                }),
                switchMap((authenticationResponse: LoginAuthenticateResponseDTO) => {
                    return this.authService.getSessionInfo().pipe(map((session: Session) => ({ authenticationResponse, session })));
                }),
                tap(({ session }) => {
                    this.store.dispatch(setSession({ session: { ...session } }));
                }),
                switchMap(({ authenticationResponse }) => {
                    switch (authenticationResponse.status) {
                        case LoginAuthenticateStatus.AuthenticatedWithoutTwoFactor:
                            this.logInfo('User authenticated without a second factor');
                            return [
                                setRequestInFlight({ requestInFlight: false }),
                                redirect(),
                            ];
                        case LoginAuthenticateStatus.RequiresPasswordChange:
                            this.logInfo('User requires a password change');
                            this.navigateTo('/change-password');
                            return [
                                setRequestInFlight({ requestInFlight: false }),
                                setMessage({ message: YOUR_PASSWORD_HAS_EXPIRED }),
                            ];
                        case LoginAuthenticateStatus.RequiresTwoFactorAuthentication: {
                            this.logInfo('User requires two-factor authentication');
                            const twoFactorUserStatus = (authenticationResponse as LoginAuthenticateResponseRequiresTwoFactorDTO)
                                .twoFactorUserStatus;
                            const authMethods = availableAuthMethods(twoFactorUserStatus.devices);

                            if (authMethods.length === 1) {
                                return [sendTwoFactorNotification({ authMethod: { ...authMethods[0], automaticallySelected: true } })];
                            } else {
                                const firstPushAuthMethod = findFirstPushAuthMethod(authMethods);
                                if (firstPushAuthMethod) {
                                    return [sendTwoFactorNotification({ authMethod: firstPushAuthMethod })];
                                } else {
                                    this.logInfo('User does not have a push-capable device');
                                    this.navigateTo('/authentication-selection');
                                    return [
                                        setTwoFactorUserStatus({ twoFactorUserStatus }),
                                        clearMessage(),
                                    ];
                                }
                            }
                        }
                        default:
                            this.logError(`Unhandled authentication response: ${authenticationResponse.status}`);
                            throw new Error(`Unhandled authentication response: ${authenticationResponse.status}`);
                    }
                }),
                catchError((err: HttpErrorResponse) => {
                    this.store.dispatch(setRequestInFlight({ requestInFlight: false }));
                    switch (err.status) {
                        case 401:
                        case 403:
                        case 500:
                            this.logError('Error:', err);
                            return of(setMessage({ message: err.error.message }));
                        default:
                            this.logError('Error:', err);
                            return [
                                resetLoginState(),
                                setMessage({ message: err.error.message }),
                            ];
                    }
                }),
            )));
    });

    resetLoginState$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(resetLoginState),
        );
    }, { dispatch: false });

    logout$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(logout),
            preventErrorsFromCompletingStream(pipe(
                tap(() => this.store.dispatch(setRequestInFlight({ requestInFlight: true }))),
                switchMap(() => this.authService.logout()),
                tap(() => this.store.dispatch(setRequestInFlight({ requestInFlight: false }))),
                switchMap(() => this.logout()),
                catchError((err: HttpErrorResponse) => {
                    this.logError('Error logging out:', err);
                    this.store.dispatch(setRequestInFlight({ requestInFlight: false }));
                    return this.logout();
                }))),
        );
    });

    changePassword$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(changePassword),
            preventErrorsFromCompletingStream(pipe(
                tap(() => this.store.dispatch(setRequestInFlight({ requestInFlight: true }))),
                switchMap(({ oldPassword, newPassword }: { oldPassword: string, newPassword: string }) => {
                    return this.authService.changePassword({ oldPassword, newPassword });
                }),
                switchMap(() => this.authService.getSessionInfo()),
                tap((session: Session) => this.store.dispatch(setSession({ session }))),
                tap(async () => this.router.navigate(['/'])),
                map(() => setRequestInFlight({ requestInFlight: false })),
                catchError((response: HttpErrorResponse) => {
                    return [
                        setMessage({ message: response.error.message }),
                        setRequestInFlight({ requestInFlight: false }),
                    ];
                }))),
        );
    });

    clearTrustedDeviceToken$: Observable<void> = createEffect(() => {
        return this.actions$.pipe(
            ofType(clearTrustedDeviceToken),
            switchMap(() => this.store.select(selectUsername)),
            map((username) => {
                if (username) {
                    this.authService.clearTrustedDeviceToken(username);
                }
            }),
        );
    }, { dispatch: false });

    setTrustedDeviceToken$: Observable<void> = createEffect(() => {
        return this.actions$.pipe(
            ofType(setTrustedDeviceToken),
            concatLatestFrom(() => this.store.select(selectUsername)),
            map(([{ token }, username]) => {
                if (username) {
                    this.authService.setTrustedDeviceToken(username, token);
                } else {
                    throw new Error('Cannot set trusted device token, username is null!');
                }
            }),
        );
    }, { dispatch: false });

    constructor(
        private readonly actions$: Actions,
        private readonly authService: AuthService,
        private readonly router: Router,
        private readonly store: Store<AuthState>,
        logger: Logger,
    ) {
        super(logger);
    }

    private navigateTo(path: string): void {
        this.router.navigate([path]).then(
            (result: boolean) => {
                this.logDebug(`Navigation to ${path} succeeded?`, result);
            },
            (error: Error) => {
                this.logError(`Navigation to ${path} failed:`, error);
            },
        );
    }

    private logout(): Action[] {
        this.logDebug('Logging out');
        this.navigateTo('/');
        return [
            resetLoginState(),
            clearSession(),
        ];
    }
}

function availableAuthMethods(devices?: Device[]): AuthMethod[] {
    return devices?.flatMap((device) => {
        return device.capabilities
            .map((capability) => ({ device, factor: capability }))
            .filter((method) => method.factor !== 'auto');
    }) ?? [];
}

function findFirstPushAuthMethod(authMethods: AuthMethod[]): AuthMethod | undefined {
    return authMethods.find((authMethod) => authMethod.factor === 'push');
}
