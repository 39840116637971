import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { interval, Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { AuthState } from 'app/auth/store';
import { verifyPasscode } from 'app/auth/store/auth/auth.actions';
import { selectRequestInFlight } from 'app/auth/store/auth/auth.selectors';
import { selectAuthMethod } from 'app/auth/store/auth-method';
import { AuthMethod } from 'app/auth/store/auth-method/auth-method.state';
import { setFailure } from 'app/auth/store/failure';
import { setMessage } from 'app/auth/store/message';
import { phoneNumberMask } from 'app/auth/utils/phone-number-mask';

@Component({
    selector: 'app-passcode',
    templateUrl: './passcode.component.html',
    styleUrls: ['./passcode.component.scss'],
})
export class PasscodeComponent implements AfterViewInit, OnInit, OnDestroy {
    @ViewChildren('codeInput') private readonly codeDiv: QueryList<ElementRef>;

    requestInFlight$: Observable<boolean>;

    label: string = 'Passcode';
    passcode: string = '';

    tickerSubscription: Subscription;
    timer: number = 59;

    constructor(
        private readonly store: Store<AuthState>,
        private readonly router: Router,
    ) {
        this.requestInFlight$ = this.store.select(selectRequestInFlight);
    }

    ngOnInit(): void {
        this.store.select(selectAuthMethod)
            .pipe(filter((value): value is AuthMethod => !!value))
            .subscribe((authMethod) => {
                if (authMethod.automaticallySelected) {
                    this.setupTimeoutTicker();
                }
                const { factor, device } = authMethod;
                switch (factor) {
                    case 'sms':
                        this.label = `SMS Confirmation Sent to ${phoneNumberMask(device.displayName ?? 'your phone')}`;
                        break;
                    case 'token_otp':
                        this.label = 'Input passcode from the Duo token';
                        break;
                    default:
                        this.label = 'Input passcode from the Duo app';
                        break;
                }
            });
    }

    ngAfterViewInit(): void {
        const pwInput = document.getElementsByTagName('input');

        if (pwInput.length) {
            pwInput[0].focus();
        }

        this.codeDiv.changes.subscribe((list: QueryList<ElementRef>) => {
            if (list.length) {
                list.first.nativeElement.focus();
            }
        });
    }

    ngOnDestroy(): void {
        this.tickerSubscription?.unsubscribe();
    }

    setupTimeoutTicker(): void {
        const timeout = 59;
        this.tickerSubscription = interval(1000)
            .pipe(take(timeout))
            .subscribe({
                next: (time: number) => {
                    this.timer = timeout - time - 1;
                },
                complete: async () => {
                    this.store.dispatch(setMessage({ message: 'Confirmation Failed: Timed Out' }));
                    this.store.dispatch(setFailure());
                    await this.router.navigate(['/authentication-selection']);
                },
            });
    }

    verifyPasscode(): void {
        this.store.dispatch(verifyPasscode({ passcode: this.passcode }));
    }
}
