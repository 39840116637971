import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from 'app/core/services/auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class IpWhitelistingGuard implements CanActivate {
    constructor(
        private readonly authService: AuthService,
        private readonly router: Router,
    ) {
    }

    canActivate(): Observable<boolean | UrlTree> {
        return this.authService.getUser().pipe(
            map((user) => {
                if (user.canViewIpWhitelisting) {
                    return true;
                } else {
                    alert('You have no entitlements for managing ip whitelisting');
                    return this.router.createUrlTree(['login']);
                }
            }),
            catchError(() => {
                return of(this.router.createUrlTree(['login']));
            }),
        );
    }
}
