import { Injectable } from '@angular/core';

import { environment } from './environment';
import { Environment } from './environment.base';

export { Environment };

@Injectable({
    providedIn: 'root',
})
export class EnvironmentService implements Environment {
    readonly production: boolean = environment.production;
    readonly dev: boolean = environment.dev;
}
