import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { AuthState } from 'app/auth/store';
import { redirect } from 'app/auth/store/redirect';
import { selectIsLoggedIn } from 'app/auth/store/session/session.selectors';
import { Logger } from 'app/shared/services/logging/logger';
import { NamedLogger } from 'app/shared/utils/logging/named-logger';

@Injectable({
    providedIn: 'root',
})
export class LoginPageGuard extends NamedLogger implements CanActivate {
    constructor(
        logger: Logger,
        private readonly store: Store<AuthState>,
    ) {
        super(logger);
    }

    canActivate(): Observable<boolean> {
        return this.store.select(selectIsLoggedIn).pipe(
            filter((isLoggedIn) => isLoggedIn !== undefined),
            map((isLoggedIn) => !isLoggedIn),
            tap((canAccessLoginPage) => {
                this.logDebug('Can access login page?', canAccessLoginPage);
                if (!canAccessLoginPage) {
                    this.logDebug('User is already logged in, redirecting...');
                    this.store.dispatch(redirect());
                }
            }),
        );
    }
}
